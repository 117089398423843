// OrderRow.tsx

import React, { useState } from "react";
import { Customer, Provider } from ".././Types";
import ProviderModal from "./ProviderModal";

import { formatBase64Image, formatDateTimeFromTimestamp } from ".././Functions";

import DispatchModal from "./DispatchModal";
import OrderModal from "./OrderModal";
import { Order } from ".././Types";
import VerifyProviderModal from "./VerifyProviderModal";
import CustomerModal from "./CustomerModal";
import { activeColor, navy } from "../../styles";
import { MdAccountCircle } from "react-icons/md";

interface CustomerRowProps {
  customer: Customer;
  //isModalOpen: boolean;
}

const CustomerRow: React.FC<CustomerRowProps> = ({ customer }) => {
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false); // Add this line to manage the modal's visibility

  const toggleModal = () => {
    setIsCustomerModalOpen(!isCustomerModalOpen);
  };

  const columnWidths = {
    image: "5%",
    email: "25%",
    name: "25%",
    created: "25%",
    details: "20%",
  };

  const scrollableListContainerStyles: React.CSSProperties = {
    flex: 0.9, // Takes up all available space in a flex container
    overflowY: "auto", // Enable vertical scrolling
  };

  const rowStyles: React.CSSProperties = {
    //padding: '40px',
    fontFamily:'PoppinsMedium',
    border: "1px solid #000000",
    display: "flex",
    flexDirection: "row",
    fontSize: 20,
    alignItems: "center",
    marginBottom: 5,
    marginTop: 5,
    //marginLeft: 10,
    //marginRight: 10,
  };

  /*
  const getIdColumnStyle = (status: string): React.CSSProperties => {
    let backgroundColor = "blue"; // Default background color

    if (status === "Pending") {
      backgroundColor = "#FFCC00"; // Darker yellow
    } else if (status === "Scheduled") {
      backgroundColor = "purple";
    } else if (status === "Ongoing") {
      backgroundColor = "blue";
    } else if (status === "Completed") {
      backgroundColor = "green";
    } else if (status === "Canceled") {
      backgroundColor = "red";
    }

    return {
      textAlign: "center",
      backgroundColor,
      color: "white",
      fontSize: "14px",
      fontWeight: "700",
      flexBasis: columnWidths.status,
      borderRadius: 7,
      paddingTop: 7,
      paddingBottom: 7,
    };
  };
*/

  const columnStyles = (width: string): React.CSSProperties => ({
    flexBasis: width,
    display: "flex",
    flexDirection: "row",
    //backgroundColor: "pink",
    padding: "10px",
    fontSize: "14px",
    color: "black",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
  });

  return (
    <div style={scrollableListContainerStyles}>
      <li key={customer.UID} style={rowStyles}>
        
        <div style={columnStyles(columnWidths.image)}>
          {customer.profileImage ? (
            <img
              src={formatBase64Image(customer.profileImage)}
              alt="Provider Profile Icon"
              style={{
                width: "35px",
                height: "35px",
                borderRadius: "50%",
                border: "3px solid #007ae4",
                marginLeft: "20px",
                position: "absolute",
                objectFit: "cover",
              }}
            />
          ) : (
            <MdAccountCircle
              size="35"
              style={{
                borderRadius: "50%",
                border: "3px solid #007ae4",
                marginLeft: "20px",
                position: "absolute",
                objectFit: "cover",
              }}
            />
          )}
        </div>

        <div style={columnStyles(columnWidths.email)}>
          <span>{customer.emailAddress}</span>
        </div>

        <div style={columnStyles(columnWidths.name)}>
          {customer.firstName ? (
            <span>
              {customer.firstName} {customer.lastName}
            </span>
          ) : (
            <span>Yet to complete verification</span>
          )}
        </div>
        <div style={columnStyles(columnWidths.created)}>
          <span>{formatDateTimeFromTimestamp(customer.createdAt)}</span>
        </div>

        <div style={columnStyles(columnWidths.details)}>
          <button
            onClick={toggleModal}
            style={{
              fontSize: "16px",
              color: "white", // Text color
              fontFamily: "PoppinsLight",
              backgroundColor: "black", // Background color
              borderRadius: "5px", // Make it round
              padding: "5px 10px", // Padding for sizing (adjust as needed)
              border: "none", // Remove default button border
              cursor: "pointer", // Change cursor to pointer on hover
              outline: "none", // Remove focus outline
              //marginRight: 40,
            }}
          >
            Details
          </button>
        </div>
      </li>
      {isCustomerModalOpen && (
        <CustomerModal
          isOpen={isCustomerModalOpen}
          onClose={() => toggleModal()}
          customerInfo={customer} // Pass customerInfo as a prop
        />
      )}
    </div>
  );
};

/*

  const toggleExpand = () => {
    if (!isDropdownOpen) {
      // setExpanded(!expanded);
    }
  };

  const handleOptionClick = (option: string) => {
    // Handle the selected option (e.g., reschedule or cancel)
    console.log(`Selected option: ${option}`);
    // Close the dropdown menu
    setIsDropdownOpen(false);
  };

  const handleDispatchClick = (event: React.MouseEvent) => {
    // Prevent the click event from propagating to the row element
    event.stopPropagation();
    toggleModal();
  };

  const handleDropDownClick = (event: React.MouseEvent) => {
    // Prevent the click event from propagating to the row element
    event.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };


 const [expanded, setExpanded] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(order.orderStatus);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);



const DispatchButton: React.FC<{
  isOpen: boolean;
  handleDispatchClick: (event: React.MouseEvent) => void;
}> = ({ isOpen, handleDispatchClick }) => {
  return (
    <div style={{ position: "relative", marginRight: 10 }}>
      <button onClick={handleDispatchClick} style={{}}>
        Dispatch
      </button>
    </div>
  );
};

{expanded && (
  <div style={styles.expandedDetails}>
    <div style={styles.leftPart}>
      <span>Address Details:</span>
      <br />
      <span>{order.address.streetAddress}</span>
      <br />
      <span>{order.address.stateAddress}</span>
      <br />
      <span>{order.address.zipCodeAddress}</span>
    </div>
    <div style={styles.rightPart}>
      <span>Order Placed On:</span>
      <br />
      <span>{order.dateOrderPlaced}</span>
    </div>
    <div style={styles.rightPart}>
      <span>Drop Off Preference:</span>
      <br />
      <span>{order.dropOffPreference}</span>
    </div>
  </div>
)}
*/

export default CustomerRow;
