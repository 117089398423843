import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { db, firebase } from "../../App";
import "./Pricing.css";
import { PricingData } from ".././Types";
import { navy } from "../../styles";
import CouponModal from "./CouponModal";
import GradientContainer from "./GradientContainer";
import TitleBar from ".././TitleBar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/redux";

interface Coupon {
  Name: string;
  Code: string;
  Amount: number;
  Value: number;
}

const Pricing = () => {
  const [editedItem, setEditedItem] = useState<PricingData | null>(null);
  const [newPrice, setNewPrice] = useState<number | null>(null);
  //const [coupon, setCoupon] = useState<Coupon[]>([]);
  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);

  //const [pricing, setPricing] = useState<PricingData[]>([]); // Initialize pricing as an empty array of PricingData type

  const pricing = useSelector((state: RootState) => state.pricing.pricing);
  const coupon = useSelector((state: RootState) => state.coupons.coupons);

  const [activeTab, setActiveTab] = useState<"basePrices" | "coupons">(
    "basePrices"
  ); // Added state for active tab

  const handleEdit = (pricingItem: PricingData) => {
    setEditedItem(pricingItem);
    setNewPrice(pricingItem.Value); // Initialize the input field with the current price
  };

  const handleSave = async () => {
    if (editedItem && newPrice !== null) {
      try {
        // Get the document reference based on the editedItem's ID
        const pricingItemDocRef = doc(db, "Pricing", editedItem.id);

        // Update the 'Value' field in the document
        await updateDoc(pricingItemDocRef, {
          Value: newPrice,
        });

        // Update the local state with the new price
        /*
        setPricing((prevPricing) =>
          prevPricing.map((item) =>
            item.id === editedItem.id ? { ...item, Value: newPrice } : item
          )
        );*/

        // Clear the edited item and new price
        setEditedItem(null);
        setNewPrice(null);
      } catch (error) {
        console.error("Error saving edited item:", error);
      }
    }
  };

  return (
    <div>
      <TitleBar title="Pricing" />

      <div>
        <button
          style={{
            fontSize: "18px",
            color: "white", // Text color
            fontFamily: "PoppinsLight",
            backgroundColor: "black", // Background color
            borderRadius: "10px", // Make it round
            padding: "10px 20px", // Padding for sizing (adjust as needed)
            border: "none", // Remove default button border
            cursor: "pointer", // Change cursor to pointer on hover
            outline: "none", // Remove focus outline
            marginLeft: 40,
            marginRight: 40,
          }}
          onClick={() => setActiveTab("basePrices")}
        >
          Base Prices
        </button>
        <button
          style={{
            fontSize: "18px",
            color: "white", // Text color
            fontFamily: "PoppinsLight",
            backgroundColor: "black", // Background color
            borderRadius: "10px", // Make it round
            padding: "10px 20px", // Padding for sizing (adjust as needed)
            border: "none", // Remove default button border
            cursor: "pointer", // Change cursor to pointer on hover
            outline: "none", // Remove focus outline
            //marginRight: 40,
          }}
          onClick={() => setActiveTab("coupons")}
        >
          Coupons
        </button>
      </div>

      {activeTab === "basePrices" && (
        // Content for Base Prices tab
        <ul className="pricing-container">
          {pricing.map((pricingItem) => (
            <li key={pricingItem.id} className="pricing-item">
              <span style={{fontFamily:'Poppins'}} className="pricing-item-name">{pricingItem.id}:</span>
              <div className="pricing-item-content">
                {editedItem === pricingItem ? (
                  <>
                    <input
                      type="number"
                      value={newPrice || ""}
                      onChange={(e) => setNewPrice(Number(e.target.value))}
                    />
                    <button
                      className="pricing-item-button"
                      onClick={handleSave}
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="baseButton"
                      style={{ fontFamily: "PoppinsLight" }}
                      onClick={() => handleEdit(pricingItem)}
                    >
                      Edit
                    </button>
                    <span className="pricing-item-value"
                    style={{fontFamily:'PoppinsXlight'}}>
                      ${pricingItem.Value}
                    </span>
                  </>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}

      {activeTab === "coupons" && (
        // Content for Coupons tab (empty for now)
        <ul className="pricing-container">
          {coupon.map((couponItem) => (
            <li key={couponItem.Code} className="pricing-item">
              <span style={{fontFamily:'Poppins'}} className="pricing-item-name">Name: {couponItem.Name}</span>
              <span style={{fontFamily:'Poppins'}} className="pricing-item-name">Code: {couponItem.Code}</span>
              <div style={{fontFamily:'Poppins'}} className="pricing-item-name">
                <span className="pricing-item-value">
                  Amount: {couponItem.Amount}
                </span>
              </div>
              <div style={{fontFamily:'Poppins'}} className="pricing-item-name">
                <span className="pricing-item-value">
                  Value: ${couponItem.Value}
                </span>
              </div>
              <div className="pricing-item-content">
                <>
                  <button style={{fontFamily:'PoppinsLight'}} className="baseButton">Edit</button>
                </>
              </div>
            </li>
          ))}
          <button
            style={{
              fontSize: "16px",
              color: "white", // Text color
              fontWeight: "600",
              fontFamily:'PoppinsLight',
              backgroundColor: 'black', // Background color
              borderRadius: "10px", // Make it round
              padding: "10px 20px", // Padding for sizing (adjust as needed)
              border: "none", // Remove default button border
              cursor: "pointer", // Change cursor to pointer on hover
              outline: "none", // Remove focus outline
              marginLeft: 40,
              marginTop: 40,
            }}
            onClick={() => setIsCouponModalOpen(true)}
          >
            Add Coupon
          </button>
        </ul>
      )}

      {isCouponModalOpen && (
        <CouponModal
          isOpen={isCouponModalOpen}
          onClose={() => setIsCouponModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Pricing;
