import React, { CSSProperties, useEffect, useState } from "react";
import { formatBase64Image, formatDateTimeFromTimestamp } from ".././Functions";
import { useSelector } from "react-redux";
import { RootState } from "../../store/redux";
import OrderRow from "./OrderRow";
import { SERVER_URL } from ".././Functions";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../App";

interface ProviderModalProps {
  isOpen: boolean;
  onClose: () => void;
  providerInfo: any; // Include providerInfo in the props
}

interface ProviderData {
  UID: string;
  emailAddress: string;
  rate: number;
  priority: number;
}

interface ZoneData {
  id: string; // Optional in case of new entries where ID isn't known yet
  ZoneName: string;
  RegionalFactor: number;
  ZipCodes: string[];
  Circles: CircleData[];
  AssignedProviders: ProviderData[];
}

interface CircleData {
  center: { lat: number; lng: number };
  radius: number;
  polygon: { lat: number; lng: number }[];
}

function useAssignedZones(providerId: string) {
  const [assignedZones, setAssignedZones] = useState<ZoneData[]>([]);

  useEffect(() => {
    const fetchZones = async () => {
      try {
        const zonesCollection = collection(db, "Zones");
        const zonesSnapshot = await getDocs(zonesCollection);
        const zonesData: ZoneData[] = zonesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          AssignedProviders: Array.isArray(doc.data().AssignedProviders)
            ? doc.data().AssignedProviders.map((provider: any, index: any) => ({
                ...provider,
                priority: index + 1, // Set priority based on index in the array
              }))
            : [], // Ensure AssignedProviders is always an array
        })) as ZoneData[];

        console.log(zonesData);

        // Filter zones based on providerId or some other logic
        const filteredZones = zonesData.filter((zone) =>
          zone.AssignedProviders.some((provider) => provider.UID === providerId)
        );
        setAssignedZones(filteredZones);
      } catch (error) {
        console.error("Error fetching zones:", error);
      }
    };

    fetchZones();
  }, [providerId]); // Dependency array ensures the effect runs again if providerId changes

  return assignedZones;
}

const ProviderModal: React.FC<ProviderModalProps> = ({
  isOpen,
  onClose,
  providerInfo,
}) => {
  const orders = useSelector((state: RootState) => state.orders.orders);

  const providerOrders = orders.filter(
    (order) => order.provider === providerInfo?.UID
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const assignedZones = useAssignedZones(providerInfo.UID);

  const [deletingStatus, setDeletingStatus] = useState<{
    [uid: string]: boolean;
  }>({});

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const deleteProvider = async (uid: any) => {
    return; // Temporarily disable the function

    setDeletingStatus((prev) => ({ ...prev, [uid]: true })); // Set loading state for this UID

    try {
      // Send a DELETE request to your server API endpoint
      const response = await fetch(`${SERVER_URL}/api/delete-provider/${uid}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON response from the server
      const result = await response.json();
      console.log(result.message); // Or handle the success message as you see fit

      // Refresh the provider lists after deletion
      //await fetchProviders();
    } catch (error) {
      console.error("Error deleting provider:", error);
      // Handle the error as needed
    }
    setDeletingStatus((prev) => ({ ...prev, [uid]: false })); // Unset loading state for this UID
    onClose();
  };

  const renderDeleteButton = (uid: any) => {
    const isDeleting = deletingStatus[uid]; // Check if this UID is currently being deleted
    return (
      <button
        style={{
          ...modalStyles.baseButton,
          backgroundColor: "red", // Red color for delete button
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => deleteProvider(uid)} // Call deleteProvider with the provider's UID
        disabled={isDeleting} // Disable the button while deleting
      >
        {isDeleting ? (
          <div className="spinner-small"></div> // Show spinner when deleting
        ) : (
          "Delete Account"
        )}
      </button>
    );
  };

  if (!isOpen) return null; // Render nothing if the modal is not open
  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container}>
      <h2>Provider Details</h2>

        <div style={modalStyles.detailsContainer}>
          <div style={modalStyles.profileColumn}>
            {providerInfo.profileImage && (
              <img
                src={formatBase64Image(providerInfo.profileImage)}
                alt="Provider Profile Icon"
                style={modalStyles.profileIcon}
              />
            )}
          </div>
          <div style={modalStyles.column}>
            <div style={modalStyles.contentStyles}>
              <strong>Company Name:</strong>
              <div style={modalStyles.floatingItem}>
                <span style={modalStyles.floatingText}>
                  {providerInfo.businessName}
                </span>
              </div>
            </div>
            <div style={modalStyles.contentStyles}>
              <strong>Business Address:</strong>
              <div style={modalStyles.floatingItem}>
                <span style={modalStyles.floatingText}>
                  {providerInfo.address}
                </span>
              </div>
            </div>
            <div style={modalStyles.contentStyles}>
              <strong>Email Address:</strong>
              <div style={modalStyles.floatingItem}>
                <span style={modalStyles.floatingText}>
                  {providerInfo.emailAddress}
                </span>
              </div>
            </div>
            <div style={modalStyles.contentStyles}>
              <strong>Full Name:</strong>
              <div style={modalStyles.floatingItem}>
                <span style={modalStyles.floatingText}>
                  {providerInfo.ownerName}
                </span>
              </div>
            </div>
            <div style={modalStyles.contentStyles}>
              <strong>Main Dispatch Phone Number:</strong>
              <div style={modalStyles.floatingItem}>
                <span style={modalStyles.floatingText}>
                  {providerInfo.phoneNumber}
                </span>
              </div>
            </div>
          </div>
          <div style={modalStyles.column}>
            <div style={modalStyles.contentStyles}>
              <strong>Working Hours: </strong>
              <div style={modalStyles.floatingItem}>
                <span style={modalStyles.floatingText}>
                  {providerInfo.operationHours || "Not specified"}
                </span>
              </div>
            </div>
            <div style={modalStyles.contentStyles}>
              <strong>Services: </strong>
              <div style={modalStyles.floatingItem}>
                <span style={modalStyles.floatingText}>
                  {providerInfo.facilityType || "Not specified"}
                </span>
              </div>
            </div>
            <div style={modalStyles.contentStyles}>
              <strong>Created At: </strong>
              <div style={modalStyles.floatingItem}>
                <span style={modalStyles.floatingText}>
                  {formatDateTimeFromTimestamp(providerInfo.createdAt) ||
                    "Not specified"}
                </span>
              </div>
            </div>
            {assignedZones.length > 0 ? (
  assignedZones.map((zoneData) => {
    const providerInZone = zoneData.AssignedProviders.find(
      (provider) => provider.UID === providerInfo.UID
    );

    return (
      <div key={zoneData.id} style={modalStyles.rowContainer}>
        <div style={modalStyles.contentStyles}>
          <strong>Zone Name:</strong>
          <div style={modalStyles.floatingItem}>
            <span style={modalStyles.floatingText}>
              {zoneData.ZoneName || "Unknown"}
            </span>
          </div>
        </div>
        <div style={modalStyles.contentStyles}>
          <strong>Provider Priority:</strong>
          <div style={modalStyles.floatingItem}>
            <span style={modalStyles.floatingText}>
              {providerInZone ? providerInZone.priority : "Unknown"}
            </span>
          </div>
        </div>
        <div style={modalStyles.contentStyles}>
          <strong>Provider Rate:</strong>
          <div style={modalStyles.floatingItem}>
            <span style={modalStyles.floatingText}>
              {providerInZone ? `${providerInZone.rate}%` : "Unknown"}
            </span>
          </div>
        </div>
      </div>
    );
  })
) : (
  <div style={modalStyles.contentStyles}>
    <strong>No assigned zones found for this provider.</strong>
  </div>
)}


          </div>
        </div>

        <hr style={modalStyles.separator} />

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: 20,
          }}
        >
          {renderDeleteButton(providerInfo.UID)}
          <button
            style={{
              ...modalStyles.baseButton,
              backgroundColor: "#Ee9c00", // Blue color
            }}
          >
            Disable Account
          </button>
          <button
            style={{
              ...modalStyles.baseButton,
              backgroundColor: "#0069A2", // Blue color
            }}
          >
            Direct Messaging Portal
          </button>
        </div>

        <div
          style={{
            ...modalStyles.barContainer,
            padding: "0", // Set padding to 0 for this specific div
          }}
        >
          {providerOrders.map((order: any, index: any) => (
            <OrderRow
              key={index}
              order={order}
              isModalOpen={isModalOpen} // Pass modal state down to OrderRow
              toggleModal={toggleModal} // Pass toggleModal function down
            />
          ))}
        </div>

        <button style={modalStyles.closeButton} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

const modalStyles = {
  profileColumn: {
    display: "flex",
    padding: 30,
    justifyContent: "center", // Center horizontally
    width: "auto", // Take only the space it needs
  } as CSSProperties,

  profileIcon: {
    width: "100px",
    height: "100px",
    borderRadius: "20%",
    border: "3px solid #007ae4",
    objectFit: "cover",
  } as CSSProperties,

  column: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    gap: "10px", // Add some gap between fields in each column
  } as CSSProperties,

  rowContainer: {
    display: "flex",
    gap: "20px", // Adjust gap as needed
    marginBottom: "10px",
    overflow: "hidden", // Hide overflow to prevent wrapping
    whiteSpace: "nowrap", // Prevent wrapping of content
  } as CSSProperties,
  
  
  floatingItem: {
    display: "flex",
    alignItems: "center",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "100px",
    backgroundColor: "#fff",
    textAlign: "left",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    margin: "0 15px 0px 0", // Adjust margins for spacing
  } as CSSProperties,
  
  floatingText: {
    color: "#000",
    fontSize: "18px",
    fontFamily: "Roboto-Medium",
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  } as CSSProperties,
  

  detailsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  } as CSSProperties,

  barContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
    padding: 20,
    backgroundColor: "#f5f5f5",
    border: "0px solid #000000",
    borderRadius: 15,
  } as CSSProperties,
  flexContainer: {
    display: "flex",
    fontFamily: "Poppins",
    justifyContent: "space-between", // Adjust this as needed for your layout
  },
  separator: {
    border: "0",
    height: "1px",
    backgroundImage:
      "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0))",
    margin: "20px 0", // Adjust margin as needed
  } as CSSProperties,
  baseButton: {
    fontSize: "15px",
    color: "white",
    fontFamily: "PoppinsLight",
    margin: 5,
    backgroundColor: "gray",
    fontWeight: "600",
    borderRadius: "5px",
    padding: "10px 10px",
    border: "none",
    cursor: "pointer",
    outline: "none",
  } as CSSProperties,
  inputStyles: {
    borderRadius: "10px", // Rounded edges
    border: "1px solid black", // Black border
    padding: "20px", // Padding for input
    marginTop: "10px", // Margin below the input
    marginBottom: "10px", // Margin below the input
  } as CSSProperties,
  headerText: {
    fontSize: "18px", // Larger font size for headers
    fontWeight: "bold", // Bold font weight for emphasis
    color: "#333", // Dark color for text
    textAlign: "center", // Center-align the text
    margin: "10px 0", // Margin for spacing above and below the text
    padding: "5px", // Optional padding
    marginBottom: 30,
    borderBottom: "1px solid #ddd", // Optional bottom border for a subtle line separator
  } as CSSProperties,
  centeredMessage: {
    display: "flex",
    fontSize: 30,
    fontWeight: "700",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    height: "100%", // Take full height of the container
    textAlign: "center", // Center text alignment
  } as CSSProperties,
  vehicleIcon: {
    width: "110px", // Set both width and height to the same value for a square shape
    height: "70px", // This ensures a fixed aspect ratio (1:1) for a square
    borderRadius: "30%", // Circular shape
    objectFit: "cover",
    paddingLeft: 20,
    paddingRight: 20,
  } as CSSProperties,

  additionalContentContainer: {
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
  } as CSSProperties,
  content: {
    backgroundColor: "#f5f5f5",
    marginBottom: "20px",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
    borderRadius: 15,
    border: "0px solid #000000",
  } as CSSProperties,
  providerDetailRow: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0", // Add padding for each row if needed
  } as CSSProperties,

  closeButton: {
    fontSize: "18px",
    color: "white", // Text color
    fontFamily: "PoppinsLight",
    backgroundColor: "black", // Background color
    borderRadius: "10px", // Make it round
    padding: "5px 10px", // Padding for sizing (adjust as needed)
    border: "none", // Remove default button border
    cursor: "pointer", // Change cursor to pointer on hover
    outline: "none",
  } as CSSProperties,
  background: {
    position: "fixed" as const, // Use 'as const' to narrow down the string literal type
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1001,
  } as CSSProperties, // Assert the entire object as CSSProperties
  container: {
    backgroundColor: "white",
    padding: "20px",
    fontFamily: "Poppins",
    minWidth: "80%",
    borderRadius: "5px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxHeight: "85vh",
    overflowY: "auto" as const, // Use 'as const' for string literals
    zIndex: 1001,
  } as CSSProperties,
  contentStyles: {
    marginBottom: "10px",
  } as CSSProperties,
};

export default ProviderModal;
