import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginForm from "./components/LoginForm";
import ContactForm from "./components/Admin/ContactForm";
import SignUpForm from "./components/SignUpForm";
import Index from "./components/Admin/Index";
import Home from "./components/Admin/Home";
import { AuthContext, AuthProvider } from "./Auth-Context";

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import { Provider } from "react-redux"; // Import Provider
import store from "./store/redux"; // Adjust the import path to where your store is defined
import Services from "./components/Admin/HomeServices";
import ResponsiveCard from "./components/Provider/ResponsiveCard";
import ProvIndex from "./components/Provider/ProvIndex";

import storeProv from "./components/Provider/store/store"; // Adjust the import path to where your store is defined
import HomeNew from "./components/Admin/HomeNew";
import LoadingSpinner from "./components/LoadingSpinner";

export const firebaseConfig = {
  apiKey: "AIzaSyBXwdnlxNTy8lKwFs4Iq07YPTtvPLVpmDc",
  authDomain: "drip-8d694.firebaseapp.com",
  projectId: "drip-8d694",
  storageBucket: "drip-8d694.appspot.com",
  messagingSenderId: "162244603908",
  appId: "1:162244603908:web:29cc7ddea429d9bd430551",
  measurementId: "G-THPNLBK5VK",
  databaseUrl: "https://drip-8d694-default-rtdb.firebaseio.com/",
};

const firebase = initializeApp(firebaseConfig);
const db = getFirestore(firebase);

export { firebase, db };

function App() {
  function RoleProtectedRoute({ children, allowedRoles }: any) {
    const { userRole, isProviderVerified } = useContext(AuthContext);

    if (userRole === null) {
      // Still determining the user's role, show spinner
      return (
        <LoadingSpinner/>

      );
    } else if (userRole === "out" || !allowedRoles.includes(userRole)) {
      // No valid user or not allowed, redirect to login
      console.log("Redirecting to login, role:", userRole);
      return <Navigate to="/login" />;
    } else if (userRole === "provider" && !isProviderVerified) {
      // If provider and not verified, redirect to ResponsiveCard
      return <ResponsiveCard />;
    }

    return children;
  }

    /*
  fetch('http://16.170.89.234:3001/test')
  .then(response => response.text())
  .then(text => console.log(text))
  .catch(error => console.error('Error:', error));
  */
 
  return (
    <AuthProvider>
      <Provider store={storeProv}>
        <Router>
          <Routes>
            <Route path="/" element={<HomeNew />} />
            <Route path="/form" element={<ContactForm />} />
            <Route path="/services" element={<Services />} />
            <Route path="/register" element={<SignUpForm />} />
            <Route path="/login" element={<LoginForm />} />

            {/* Restricted access to Index */}
            <Route
              path="/index"
              element={
                <RoleProtectedRoute allowedRoles={["management"]}>
                  <Provider store={store}>
                    <Index />
                  </Provider>
                </RoleProtectedRoute>
              }
            />

            {/* Restricted access to ProvIndex */}
            <Route
              path="/indexProv"
              element={
                <RoleProtectedRoute allowedRoles={["provider"]}>
                    <ProvIndex />
                </RoleProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
      </Provider>
    </AuthProvider>
  );
}

export default App;
