import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { UserData } from ".././Types";
import ZipCodeManagementModal from "./ZipCodeManagementModal";
import "./Dashboard.css";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../App";
import { IoRefreshCircleOutline } from "react-icons/io5";
import GradientContainer from "./GradientContainer";
import { SERVER_URL}  from ".././Functions"

const rowStyles: React.CSSProperties = {
  borderRadius: '20px',
  padding: "40px",
  //border: "1px solid #000000",
  margin: "10px",
  backgroundColor: "#f5f5f5",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  color: "black",
  fontSize: "20px",
};

interface Assignment {
  id: string;
  priority: number;
  ZipCode: string;
  rate: number;
}

interface AssignedProvider {
  UID: string;
  Rate: string;
  Priority?: number; // Include this field if your data structure has a priority
}

interface ZipCodeData {
  AssignedProviders: AssignedProvider[];
  ZipCode: string;
}

const Assignments: React.FC = () => {
  const auth = getAuth();
  const [user, setUser] = useState<User | null>(null);
  const [users, setUsers] = useState<UserData[] | null>(null); // Allow for null to represent loading
  //const [pendingUsers, setPendingUsers] = useState<UserData[]>([]); // State for storing fetched users

  const [isModalOpen, setIsModalOpen] = useState(false); // State to track modal open/close
  const [selectedProviderUserData, setSelectedProviderUserData] =
    useState<UserData | null>(null); // State for selected provider data

  const fetchProvidersAndAssignments = async () => {
    setUsers(null);
    console.log("ran");
    try {
      const response = await fetch(`${SERVER_URL}/api/users`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const verifiedProviders = data.verifiedProviders;

      // Fetch assignments for each provider and add to their data
      const providersWithAssignments = await Promise.all(
        verifiedProviders.map(async (provider: any) => {
          const zipCodesSnapshot = await getDocs(collection(db, "ZipCodes"));
          const assignments: Assignment[] = []; // Explicitly type the assignments array

          zipCodesSnapshot.forEach((zipDoc) => {
            const zipCodeData = zipDoc.data() as ZipCodeData;
            // Handle the object structure of AssignedProviders
            const assignedProvidersEntries = Object.entries(
              zipCodeData.AssignedProviders || {}
            );

            for (const [
              priority,
              assignedProvider,
            ] of assignedProvidersEntries) {
              if (assignedProvider.UID === provider.uid) {
                assignments.push({
                  id: zipDoc.id,
                  priority: Number(priority), // Convert the key to a number for priority
                  ZipCode: zipCodeData.ZipCode,
                  rate: Number(assignedProvider.Rate),
                });
              }
            }
          });

          return { ...provider, assignments };
        })
      );

      setUsers(providersWithAssignments);
    } catch (error) {
      console.error("Error fetching providers and assignments:", error);
    }
  };

  useEffect(() => {
    fetchProvidersAndAssignments();
  }, []);

  useEffect(() => {
    // Listen for changes in the user's authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUser(user);
      } else {
        // User is signed out
        setUser(null);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  // Function to render a list of zip codes for a user or an "Add Zipcode" button if none are assigned
  const openModal = (userData: UserData) => {
    setSelectedProviderUserData(userData);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            fontSize: 35,
            color: "black",
            paddingLeft: 40,
            fontFamily:'Poppins'
          }}
        >
          Assignments
        </h1>
        <button
          className="baseButton"
          style={{
            marginLeft: "20px",
            padding: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={fetchProvidersAndAssignments}
        >
          <IoRefreshCircleOutline size={20} />
        </button>
      </div>
      {users === null ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <p style={{ fontSize: "24px" }}>Loading...</p>
        </div>
      ) : users.length > 0 ? (
        <ul>
          {users.map((userData) => (
            <li key={userData.uid} style={rowStyles}>
              <div>
                <span style={{fontFamily:'PoppinsSemi' }}>Email:</span>
                <br />
                <span style={{ fontFamily:'PoppinsLight'}}>{userData.emailAddress}</span>
                <br />
                <span style={{ fontFamily:'PoppinsSemi'}}>UID:</span>
                <br />
                <span style={{ fontFamily:'PoppinsLight'}}>{userData.uid}</span>
              </div>
              <div></div>
              <div>
                <span style={{ fontFamily:'PoppinsSemi' }}>Assignments:</span>
                <br />
                {userData.assignments && userData.assignments.length > 0 ? (
                  <ul>
                    {userData.assignments.map((assignment) => {
                      return (
                        <li
                          key={assignment.id}
                          style={{
                            marginTop: "20px",
                            fontFamily:'PoppinsLight',
                            border: "1px solid #000000",
                            borderRadius: "5px",
                            padding: "20px",
                          }}
                        >
                          Priority: {assignment.priority}, Zip Code:{" "}
                          {assignment.ZipCode}, Rate: {assignment.rate}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p>No assignments available</p>
                )}
                <button
                  className="baseButton"
                  style={{ marginTop: "40px",fontFamily:'PoppinsLight',fontSize:15 }}
                  onClick={() => openModal(userData)}
                >
                  Manage Assignments
                </button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <p style={{ fontSize: "24px" }}>No providers found.</p>
        </div>
      )}
      {isModalOpen && (
        <ZipCodeManagementModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          //@ts-ignore
          userData={selectedProviderUserData}
          fetchUsers={fetchProvidersAndAssignments}
        />
      )}
    </div>
  );
};

export default Assignments;
