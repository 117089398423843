import { combineReducers } from 'redux';
import orderReducer from './orderReducer';
import customerReducer from './customerReducer';
import providerReducer from './providerReducer';
import invoiceReducer from './invoiceReducer';
import zonesReducer from './zonesReducer';

const rootReducer = combineReducers({
  orders: orderReducer,
  customers: customerReducer,
  provider: providerReducer,
  invoices: invoiceReducer, // Add the invoice reducer
  zoneData: zonesReducer, // Add the zones reducer under an appropriate key
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
