import { AnyAction } from 'redux';
import { SET_PROVIDER_DETAILS, SET_LOADING_PROVIDER } from '../actions/types';

interface ProviderState {
  details: any | null;
  loading: boolean;
}

const initialState: ProviderState = {
  details: null,
  loading: false, // Initially, it's not loading
};

const providerReducer = (state = initialState, action: AnyAction): ProviderState => {
  switch (action.type) {
    case SET_LOADING_PROVIDER:
      return {
        ...state,
        loading: action.payload, // Set loading state specifically for provider
      };
    case SET_PROVIDER_DETAILS:
      return {
        ...state,
        details: action.payload,
        loading: false, // Stop loading once data is fetched
      };
    default:
      return state;
  }
};


export default providerReducer;
