import React, { CSSProperties, useEffect, useState } from "react";
import { Customer, Order, Provider } from ".././Types";
import {
  fetchCustomer,
  fetchProvider,
  formatEpochDate,
  formatEpochTime,
} from ".././Functions";
import { formatBase64Image } from ".././Functions";
import DispatchModal from "./DispatchModal";
import CustomerModal from "./CustomerModal";
import ProviderModal from "./ProviderModal";
import ProcessHistoryModal from "./ProcessHistoryModal";
import MapComponent from ".././Map";
import { navy } from "../../styles";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../App";
import GoogleMapReact from "google-map-react";

interface OrderModalProps {
  order: Order;
  closeModal: () => void;
}

const OrderModal: React.FC<OrderModalProps> = ({ order, closeModal }) => {
  const [providerInfo, setProviderInfo] = useState<Provider | null>(null);
  const [customerInfo, setCustomerInfo] = useState<Customer | null>(null);
  const [isDispatchModalOpen, setIsDispatchModalOpen] = useState(false); // State variable for DispatchModal

  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false); // State variable for DispatchModal
  const [isProviderModalOpen, setIsProviderModalOpen] = useState(false); // State variable for DispatchModal
  const [isProcessHistoryModalOpen, setIsProcessHistoryModalOpen] =
    useState(false);

    const defaultProps = {
      center: {
        lat: order.address.location.latitude,
        lng: order.address.location.longitude,
      },
      zoom: 11,
    };
    

    const renderMarker = (map: any, maps: any) => {
      if (order.address && order.address.location) {
        new maps.Marker({
          position: {
            lat: order.address.location.latitude,
            lng: order.address.location.longitude,
          },
          map,
          title: order.orderID,
        });
      }
    };
    


    const handleApiLoaded = ({ map, maps }: any) => {
      renderMarker(map, maps);
    };
    

  useEffect(() => {
    const fetchData = async () => {
      // Fetching provider data
      if (order.provider) {
        try {
          const providerData = await fetchProvider(order.provider);
          setProviderInfo(providerData);
        } catch (error) {
          console.error("Error fetching provider data:", error);
          // Handle provider errors
        }
      }

      // Fetching customer data
      if (order.orderedBy) {
        try {
          const customerData = await fetchCustomer(order.orderedBy);
          setCustomerInfo(customerData);
        } catch (error) {
          console.error("Error fetching customer data:", error);
          // Handle customer errors
        }
      }
    };

    fetchData();
  }, [order.provider, order.orderedBy]);

  const deleteOrder = async (orderId: any) => {
    if (window.confirm("Are you sure you want to delete this order?")) {
      try {
        await deleteDoc(doc(db, "Orders", orderId));
        console.log("Order deleted successfully");
        // The UI will automatically update due to the onSnapshot listener reacting to changes in the Firestore collection
      } catch (error) {
        console.error("Error deleting order:", error);
      }
    }
  };

  const toggleModal = (
    modalType: "dispatch" | "provider" | "customer" | "processHistory"
  ) => {
    switch (modalType) {
      case "dispatch":
        setIsDispatchModalOpen(!isDispatchModalOpen);
        break;
      case "provider":
        setIsProviderModalOpen(!isProviderModalOpen);
        break;
      case "customer":
        setIsCustomerModalOpen(!isCustomerModalOpen);
        break;
      case "processHistory":
        setIsProcessHistoryModalOpen(!isProcessHistoryModalOpen);
        break;
      default:
        // Optional: Handle unknown modal type
        console.warn("Unknown modal type:", modalType);
        break;
    }
  };

  const DownloadInvoiceButton = ({ pdfBase64 }: any) => {
    const downloadPdf = () => {
      if (!pdfBase64) {
        alert("No invoice available for download.");
        return;
      }

      // Convert Base64 string to a Blob
      const byteCharacters = atob(pdfBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "invoice.pdf"; // You might want to generate a more specific filename based on order details
      document.body.appendChild(a);
      a.click();

      // Clean up by removing the temporary anchor element and revoking the Blob URL
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };

    return (
      <button
        style={{
          ...modalStyles.baseButton,
          backgroundColor: "#007bff",
          marginLeft: 30,
        }}
        onClick={downloadPdf}
      >
        Download Invoice
      </button>
    );
  };

  return (
    <div style={modalStyles.background}>
    <div style={modalStyles.container}>
      <h2>Order Details</h2>
      <div style={modalStyles.orderDetailsContainer}>
  <div style={modalStyles.mainFlexContainer}>
    {/* First Column: 6 fields */}
    <div style={modalStyles.columnContainer}>
      <div style={modalStyles.contentStyles}>
        <strong>Order ID: </strong>
        <div style={modalStyles.floatingItem}>
          <span style={modalStyles.floatingText}>#{order.orderID}</span>
        </div>
      </div>
      <div style={modalStyles.contentStyles}>
        <strong>Address: </strong>
        <div style={modalStyles.floatingItem}>
          <span style={modalStyles.floatingText}>
            {order.address.streetAddress}, {order.address.stateAddress}
          </span>
        </div>
      </div>
      <div style={modalStyles.contentStyles}>
        <strong>Service Date: </strong>
        <div style={modalStyles.floatingItem}>
          <span style={modalStyles.floatingText}>{formatEpochDate(order.dateOfService)}</span>
        </div>
      </div>
    
      <div style={modalStyles.contentStyles}>
        <strong>Status: </strong>
        <div style={modalStyles.floatingItem}>
          <span style={modalStyles.floatingText}>{order.orderStatus}</span>
        </div>
      </div>
      <div style={modalStyles.contentStyles}>
        <strong>Service Type/Price: </strong>
        <div style={modalStyles.floatingItem}>
          <span style={modalStyles.floatingText}>{order.service}</span>
          <span style={modalStyles.floatingText}>${order.orderPrice}</span>
        </div>
      </div>
    </div>

    {/* Second Column: 5 fields */}
    <div style={modalStyles.columnContainer}>
      <div style={modalStyles.contentStyles}>
        <strong>Area: </strong>
        <div style={modalStyles.floatingItem}>
          <span style={modalStyles.floatingText}>{order.address.zipCodeAddress}</span>
        </div>
      </div>
      <div style={modalStyles.contentStyles}>
        <strong>Service Time: </strong>
        <div style={modalStyles.floatingItem}>
          <span style={modalStyles.floatingText}>{formatEpochTime(order.dateOfService)}</span>
        </div>
      </div>
      <div style={modalStyles.contentStyles}>
        <strong>Customer UID: </strong>
        <div style={modalStyles.floatingItem}>
          <span style={modalStyles.floatingText}>{order.orderedBy}</span>
        </div>
      </div>
      <div style={modalStyles.contentStyles}>
        <strong>Provider UID: </strong>
        <div style={modalStyles.floatingItem}>
          <span style={modalStyles.floatingText}>
            {order.provider ? " " + order.provider : " No provider assigned"}
          </span>
        </div>
      </div>
      <div style={modalStyles.contentStyles}>
        <strong>Date/Time Order Placed: </strong>
        <div style={modalStyles.floatingItem}>
          <span style={modalStyles.floatingText}>
            {formatEpochDate(order.dateOrderPlaced)} {formatEpochTime(order.dateOrderPlaced)}
          </span>
        </div>
      </div>
    </div>

    {/* Third Column: Map and Vehicle Image */}
    <div style={modalStyles.mapAndVehicleContainer}>
    <div style={modalStyles.mapContainer}>
                <GoogleMapReact
                 bootstrapURLKeys={{
                  key: "AIzaSyBsP52q1Y_t08O_ndbiyUuFNi9KKGgATrs", // Replace with your API key
                }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={handleApiLoaded}
                />
              </div>
      <div style={modalStyles.vehicleContainer}>
        {order.vehicle?.imageSource && (
          <>
            <img
              src={formatBase64Image(order.vehicle?.imageSource)}
              alt="Customer Profile Image"
              style={modalStyles.vehicleIcon}
            />
            <span style={modalStyles.floatingText}>
              {order.vehicle
                ? order.vehicle.make + " " + order.vehicle.model + " " + order.vehicle.color
                : " No provider assigned"}
            </span>
          </>
        )}
      </div>
    </div>
  </div>
</div>

  
        {/* Additional buttons and modals */}
        <button
          onClick={() => toggleModal("dispatch")}
          style={{
            ...modalStyles.baseButton,
            backgroundColor: "#Ee9c00", // Blue color
            marginLeft: 10,
          }}
        >
          Change State
        </button>
        <button
          onClick={() => toggleModal("processHistory")}
          style={{
            ...modalStyles.baseButton,
            backgroundColor: "#03A2FF", // Blue color
            marginLeft: 30,
          }}
        >
          Process History
        </button>
        <button
          onClick={() => deleteOrder(order.id)}
          style={{
            ...modalStyles.baseButton,
            backgroundColor: "red", // Blue color
            marginLeft: 30,
          }}
        >
          Delete Order
        </button>
        <DownloadInvoiceButton pdfBase64={order.payment} />
  
        <div style={modalStyles.additionalContentContainer}>
{/* Left side (Provider Details) */}
<div style={modalStyles.leftSide}>
  <div style={modalStyles.headerText}>Assigned Provider Details</div>
  {providerInfo ? (
    <>
      <div style={modalStyles.profileContainer}>
        {providerInfo.profileImage && (
          <img
            src={formatBase64Image(providerInfo.profileImage)}
            alt="Provider Profile Icon"
            style={modalStyles.profileIcon}
          />
        )}
        <button
          style={{
            ...modalStyles.baseButton,
            backgroundColor: "black", // Blue color
            marginLeft: "10px", // Add some space between the image and the button
            alignSelf: "center"
          }}
          onClick={() => toggleModal("provider")}
        >
          View Provider Profile
        </button>
      </div>
      <div style={modalStyles.twoColumnContainer}>
        <div style={modalStyles.contentStyles}>
          <strong>Company Name:</strong>
          <div style={modalStyles.floatingItem}>
            <span style={modalStyles.floatingText}>{providerInfo.businessName}</span>
          </div>
        </div>
        <div style={modalStyles.contentStyles}>
          <strong>Business Address:</strong>
          <div style={modalStyles.floatingItem}>
            <span style={modalStyles.floatingText}>{providerInfo.homeAddress}</span>
          </div>
        </div>
        <div style={modalStyles.contentStyles}>
          <strong>Email Address:</strong>
          <div style={modalStyles.floatingItem}>
            <span style={modalStyles.floatingText}>{providerInfo.emailAddress}</span>
          </div>
        </div>
        <div style={modalStyles.contentStyles}>
          <strong>Full Name:</strong>
          <div style={modalStyles.floatingItem}>
            <span style={modalStyles.floatingText}>
              {providerInfo.ownerName}
            </span>
          </div>
        </div>
        <div style={modalStyles.contentStyles}>
          <strong>Main Dispatch Phone Number:</strong>
          <div style={modalStyles.floatingItem}>
            <span style={modalStyles.floatingText}>{providerInfo.dispatcherPhoneNumber}</span>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div style={modalStyles.centeredMessage}>
      <span>No provider assigned</span>
    </div>
  )}
</div>

{/* Right side (Customer Details) */}
<div style={modalStyles.rightSide}>
  <div style={modalStyles.headerText}>Customer Details</div>
  {customerInfo?.profileImage && (
    <div style={modalStyles.profileContainer}>
      <img
        src={formatBase64Image(customerInfo.profileImage)}
        alt="Customer Profile Image"
        style={modalStyles.profileIcon} // Ensure you define this style
      />
      <button
        style={{
          ...modalStyles.baseButton,
          backgroundColor: "black", // Blue color
          marginLeft: "10px", // Add some space between the image and the button
          alignSelf: "center"
        }}
        onClick={() => toggleModal("customer")}
      >
        View Customer Profile
      </button>
    </div>
  )}
  <div style={modalStyles.twoColumnContainer}>
    <div style={modalStyles.contentStyles}>
      <strong>Full Name:</strong>
      <div style={modalStyles.floatingItem}>
        <span style={modalStyles.floatingText}>
          {customerInfo?.firstName} {customerInfo?.lastName}
        </span>
      </div>
    </div>
    <div style={modalStyles.contentStyles}>
      <strong>Phone Number:</strong>
      <div style={modalStyles.floatingItem}>
        <span style={modalStyles.floatingText}>{customerInfo?.phoneNumber}</span>
      </div>
    </div>
    <div style={modalStyles.contentStyles}>
      <strong>Email Address:</strong>
      <div style={modalStyles.floatingItem}>
        <span style={modalStyles.floatingText}>{customerInfo?.emailAddress}</span>
      </div>
    </div>
    <div style={modalStyles.contentStyles}>
      <strong>Gender:</strong>
      <div style={modalStyles.floatingItem}>
        <span style={modalStyles.floatingText}>{customerInfo?.gender}</span>
      </div>
    </div>
    <div style={modalStyles.contentStyles}>
      <strong>Home Address:</strong>
      <div style={modalStyles.floatingItem}>
        <span style={modalStyles.floatingText}>
          {customerInfo?.addresses && customerInfo.addresses.length > 0
            ? `${customerInfo.addresses[0].streetAddress} ${customerInfo.addresses[0].zipCodeAddress}, ${customerInfo.addresses[0].stateAddress}`
            : "No home address"}
        </span>
      </div>
    </div>
  </div>
</div>


        </div>
  
        <button style={modalStyles.closeButton} onClick={closeModal}>
          Close
        </button>
      </div>
      {isDispatchModalOpen && (
        <DispatchModal
          isOpen={isDispatchModalOpen}
          onClose={() => toggleModal("dispatch")}
        />
      )}
      {isProcessHistoryModalOpen && (
        <ProcessHistoryModal
          isOpen={isProcessHistoryModalOpen}
          onClose={() => toggleModal("processHistory")}
          orderId={order.id} // Assuming the ProcessHistoryModal needs the order ID to fetch and display the history
        />
      )}
      {isProviderModalOpen && (
        <ProviderModal
          isOpen={isProviderModalOpen}
          onClose={() => toggleModal("provider")}
          providerInfo={providerInfo} // Pass providerInfo as a prop
        />
      )}
      {isCustomerModalOpen && (
        <CustomerModal
          isOpen={isCustomerModalOpen}
          onClose={() => toggleModal("customer")}
          customerInfo={customerInfo} // Pass customerInfo as a prop
        />
      )}
    </div>
  );
  
  
};

const modalStyles = {
  background: {
    position: "fixed" as const, // Use 'as const' to narrow down the string literal type
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  } as CSSProperties, // Assert the entire object as CSSProperties


  orderDetailsContainer: {
    backgroundColor: "#f5f5f5",
    padding: "20px",
    borderRadius: "10px",
    marginBottom: "20px",
  } as CSSProperties,

  twoColumnContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "10px", // Horizontal gap between columns
    //rowGap: "5px", // Vertical gap between rows
  } as CSSProperties,
  
  profileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  } as CSSProperties,

  mainFlexContainer: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    width: "100%", // Take up the full width of the parent
    gap: "20px", // Increase gap between items
  } as CSSProperties,

  columnContainer: {
    flex: "0 0 calc(33.333% - 20px)", // Adjust the width to ensure three columns and account for gap
    marginBottom: "20px", // Add some margin between rows
  } as CSSProperties,

  mapAndVehicleContainer: {
    flex: "0 0 calc(33.333% - 20px)",
    display: "flex",
    flexDirection: "column",
    gap: "20px", // Increase gap between map and vehicle image
  } as CSSProperties,

  mapContainer: {
    backgroundColor: "white",
    width: "100%", // Keep the width constraint
    height: "300px", // Adjust the height as needed for your design
    padding: 20,
    overflow: "hidden", // Ensure the map does not overflow its container
    borderRadius: 10,
  } as CSSProperties,

  vehicleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    border: "1px solid #ddd",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  } as CSSProperties,

  floatingItem: {
    display: "flex",
    alignItems: "center",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "100px",
    backgroundColor: "#fff",
    marginBottom: "10px",
    textAlign: "left",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  } as CSSProperties,

  floatingText: {
    color: "#000",
    fontSize: "18px",
    fontFamily: "Roboto-Medium",
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  } as CSSProperties,

 



  barContainer: {
    backgroundColor: "#f5f5f5",
    width: "70%", // Take up half the width
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    padding: 20,
    //border: "1px solid #000000",
    borderRadius: 10,
  } as CSSProperties,
  leftSide: {
    backgroundColor: "#f5f5f5",
    width: "50%",
    marginRight: 30,
    marginBottom: "20px",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 10,
    //border: "1px solid #000000",
    borderRadius: 10,
    overflow:'hidden'
  } as CSSProperties,
  rightSide: {
    backgroundColor: "#f5f5f5",
    width: "50%",
    marginBottom: "20px",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 10,
    //border: "1px solid #000000",
    borderRadius: 10,
  } as CSSProperties,

  baseButton: {
    fontSize: "17px",
    color: "white",
    backgroundColor: "gray",
    fontFamily: "PoppinsLight",
    borderRadius: "5px",
    padding: "5px 10px",
    border: "none",
    cursor: "pointer",
    outline: "none",
  } as CSSProperties,
  headerText: {
    fontSize: "18px", // Larger font size for headers
    fontWeight: "bold", // Bold font weight for emphasis
    color: "#333", // Dark color for text
    textAlign: "center", // Center-align the text
    margin: "10px 0", // Margin for spacing above and below the text
    padding: "5px", // Optional padding
    marginBottom: 30,
    borderBottom: "1px solid #ddd", // Optional bottom border for a subtle line separator
  } as CSSProperties,
  centeredMessage: {
    display: "flex",
    fontSize: 30,
    fontWeight: "700",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    height: "100%", // Take full height of the container
    textAlign: "center", // Center text alignment
  } as CSSProperties,
  vehicleIcon: {
    width: "110px", // Set both width and height to the same value for a square shape
    height: "70px", // This ensures a fixed aspect ratio (1:1) for a square
    borderRadius: "10%", // Circular shape
    objectFit: "cover",
    border: "3px solid #007ae4",
    marginBottom:10,
  } as CSSProperties,
  profileIcon: {
    width: "100px", // Set both width and height to the same value for a square shape
    height: "100px", // This ensures a fixed aspect ratio (1:1) for a square
    borderRadius: "20%", // Circular shape
    border: "3px solid #007ae4",
    marginBottom: "10px",
    objectFit: "cover",
  } as CSSProperties,
  additionalContentContainer: {
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
  } as CSSProperties,

  providerDetailRow: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0", // Add padding for each row if needed
    // Add any additional styling you need for each detail row
  } as CSSProperties,



  closeButton: {
    fontSize: "18px",
    color: "white", // Text color
    fontFamily: "PoppinsLight",
    backgroundColor: "black", // Background color
    borderRadius: "10px", // Make it round
    padding: "5px 10px", // Padding for sizing (adjust as needed)
    border: "none", // Remove default button border
    cursor: "pointer", // Change cursor to pointer on hover
    outline: "none", // Remove focus outline
    //marginRight: 40,
  } as CSSProperties,

container: {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "5px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  width: "80%",
  //maxWidth: "1200px", // Ensure the width doesn't exceed this value
  maxHeight: "85vh",
  overflowY: "auto" as const, // Only vertical scrolling
  overflowX: "hidden", // Prevent horizontal scrolling
  fontFamily: "Poppins",
  backgroundSize: "400% 400%",
  animation: "gradientAnimation 15s ease infinite",
} as CSSProperties,


  contentStyles: {
    // Define your styles here
    marginBottom: "5px",
  } as CSSProperties,
};

export default OrderModal;
