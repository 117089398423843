// src/store/redux.tsx
import { configureStore } from '@reduxjs/toolkit';
import ordersReducer from './ordersReducer';
import providersReducer from './providersReducer';
import customersReducer from './customersReducer'; // Import the new customersReducer
import assignmentsReducer from './assignmentsReducer';
import prospectsReducer from './prospectsReducer';
import notificationsReducer from './notificationsReducer';
import pricingReducer from './pricingReducer';
import { Customer, Order } from '../components/Types';
import couponsReducer from './couponsReducer';


export interface RootState {
  // Add new slices to the RootState interface
  orders: { orders: Order[]; };
  providers: { providers: any[]; };
  customers: { customers: Customer[]; };
  pricing: { pricing: any[]; }; // Update the type as needed
  coupons: { coupons: any[]; }; // Update the type as needed
  assignments: { assignments: any[]; }; // Update the type as needed
  prospects: { prospects: any[]; }; // Update the type as needed
  notifications: { notifications: any[]; }; // Update the type as needed
}


const store = configureStore({
  reducer: {
    orders: ordersReducer,
    providers: providersReducer,
    customers: customersReducer,
    pricing: pricingReducer,
    coupons: couponsReducer,
    assignments: assignmentsReducer,
    prospects: prospectsReducer,
    notifications: notificationsReducer,
  },
});


export default store;
