import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { UserData } from ".././Types";
import "./Authentication.css?v=1";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import VerifyProviderModal from "./VerifyProviderModal";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../App";
import GradientContainer from "./GradientContainer";
import TitleBar from ".././TitleBar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/redux";
import { SERVER_URL}  from ".././Functions"


//loading is scuffed when modal closes and it refreshes

const Prospects: React.FC = () => {
  const auth = getAuth();

  const [user, setUser] = useState<User | null>(null);
  const contacts = useSelector((state: RootState) => state.prospects.prospects); // Adjust "RootState" and the state path according to your setup

  const [isLoading, setIsLoading] = useState(false); // State to track loading

  const [deletingStatus, setDeletingStatus] = useState<{
    [uid: string]: boolean;
  }>({});

  
  useEffect(() => {
    // Fetch user data when the component mounts
    //fetchUsers();
    // Listen for changes in the user's authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUser(user);
      } else {
        // User is signed out
        setUser(null);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);


  const deleteContact = async (contactId: string) => {
    setDeletingStatus((prev) => ({ ...prev, [contactId]: true })); // Set loading state for this contact

    try {
      // Reference to the contact document in the 'Contacts' collection
      const contactDocRef = doc(db, "Contacts", contactId);

      // Delete the document
      await deleteDoc(contactDocRef);

      console.log("Contact deleted successfully");

      // Refresh the contact list after deletion
      //await fetchContacts();
    } catch (error) {
      console.error("Error deleting contact:", error);
    }
    setDeletingStatus((prev) => ({ ...prev, [contactId]: false })); // Unset loading state for this contact
  };

  



  function formatDate(timestamp: any) {
    if (!timestamp || !timestamp.seconds) {
      return "No Date Provided";
    }

    try {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
        timeZoneName: "short",
      };

      // Convert Firestore Timestamp to JavaScript Date object
      const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
      //@ts-ignore
      return new Intl.DateTimeFormat("en-US", options).format(date);
    } catch (e) {
      console.error("Error formatting date:", e);
      return "Invalid Date";
    }
  }


  return (
    <GradientContainer>
      <TitleBar title="Prospects" />

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <p style={{ fontSize: "24px" }}>Loading...</p>
        </div>
      ) : contacts.length > 0 ? (
        <div style={scrollableListStyle}>
          <ul>
           
            {contacts.map((userData: any) => (
              <li key={userData.email} style={rowStyles}>
                <div style={columnStyle}>
                  <span style={{ fontWeight: "bold" }}>Name:</span>
                  <br />
                  <span>{userData.name}</span>
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>Email:</span>
                  <br />
                  <span>{userData.email}</span>
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>Area:</span>
                  <br />
                  <span>{userData.area}</span>
                  <br />
                </div>
                <div style={columnStyle}>
                  <span style={{ fontWeight: "bold" }}>Location:</span>
                  <br />
                  <span>
                    {userData.city},{userData.country}
                  </span>
                  <br />
                  <span>IP Address: {userData.ipAddress}</span>
                  <br />
                  <span>Coordinates: {userData.location}</span>
                </div>
                <div style={columnStyle}>
                  <span style={{ fontWeight: "bold" }}>
                    Register Date/Time:
                  </span>
                  <br />
                  <span>{formatDate(userData.timestamp)}</span>
                  <br />
                </div>

                {/* Add more fields as needed */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <button
                    className={`baseButton ${
                      deletingStatus[userData.email] ? "loading" : ""
                    }`}
                    disabled={deletingStatus[userData.email]}
                    style={{ margin: 40, backgroundColor: "red" }}
                    onClick={() => deleteContact(userData.contactId)}
                  >
                    {deletingStatus[userData.email] ? (
                      <div className="spinner"></div>
                    ) : (
                      "Delete Form"
                    )}
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <p style={{ fontSize: "24px", marginTop: -500 }}>
            No Prospects Data Available
          </p>
        </div>
      )}
   
    </GradientContainer>
  );
};

const scrollableListStyle: React.CSSProperties = {
  flex: 1,
  height: "86vh", // This will take the full viewport height
  overflowY: "scroll", // Enable vertical scrolling
  padding: "10px", // Optional padding
  marginTop: "20px", // Optional margin top
};

const columnStyle: React.CSSProperties = {
  flex: 1,
  padding: 20,
};

const rowStyles: React.CSSProperties = {
  //borderRadius: '10px',
  padding: "40px",
  border: "1px solid #000000",
  margin: "10px",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  color: "black",
  fontSize: "20px",
};

export default Prospects;
