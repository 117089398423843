// src/store/customersReducer.js
const initialState = {
  customers: [],
};

function customersReducer(state = initialState, action:any) {
  switch (action.type) {
    case 'SET_CUSTOMERS':
      return {
        ...state,
        customers: action.payload,
      };
    default:
      return state;
  }
}

export default customersReducer;

// Action creators
export const setCustomers = (customers:any) => ({
  type: 'SET_CUSTOMERS',
  payload: customers,
});
