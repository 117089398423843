import { FETCH_ORDERS, ADD_ORDERS, UPDATE_ORDER, SET_LOADING_ORDERS } from "../actions/types";
import { Order } from "../actions/types"; // Import the Order type

interface OrderState {
  orders: Order[];
  loading: boolean;
}

interface Action {
  type: string;
  payload: any;
}

const initialState: OrderState = {
  orders: [],
  loading: false,
};

const orderReducer = (state = initialState, action: Action): OrderState => {
  switch (action.type) {
    case FETCH_ORDERS:
      return {
        ...state,
        orders: action.payload,
        loading: false, // Stop loading once orders are fetched
      };
    case ADD_ORDERS:
      return {
        ...state,
        orders: [...state.orders, ...action.payload],
        loading: false, // Stop loading when new orders are added
      };
    case UPDATE_ORDER:
      return {
        ...state,
        orders: state.orders.map((order) =>
          order.id === action.payload.id ? { ...order, ...action.payload } : order
        ),
      };
    case SET_LOADING_ORDERS:
      return {
        ...state,
        loading: action.payload, // Set loading state specifically for orders
      };
    default:
      return state;
  }
};

export default orderReducer;
