// OrderRow.tsx

import React, { useState } from "react";
import DispatchModal from "./DispatchModal";
import { Location } from ".././Types";
import OrderModal from "./OrderModal"; // Import the OrderModal component
import { Order } from ".././Types";
import {  formatEpochDate, formatEpochTime } from ".././Functions";
import { activeColor, navy } from "../../styles";

interface OrderRowProps {
  order: Order;
  isModalOpen: boolean;
  toggleModal: () => void;
}

const OrderRow: React.FC<OrderRowProps> = ({
  order,
  isModalOpen,
  toggleModal,
}) => {
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false); // Add this line to manage the modal's visibility

  const toggleOrderModal = () => {
    setIsOrderModalOpen(!isOrderModalOpen);
  };

  const columnWidths = {
    id: "7%",
    customer: "18%",
    provider: "18%",
    area: "9%",
    serviceDateTime: "13%",
    serviceTypePrice: "13%",
    status: "12%",
    details: "10%",
  };

  const scrollableListContainerStyles: React.CSSProperties = {
    flex: 0.9, // Takes up all available space in a flex container
    overflowY: "auto", // Enable vertical scrolling
  };

  const rowStyles: React.CSSProperties = {
    //padding: '40px',
    borderBottom: "1px solid #000000",
    display: "flex",
    flexDirection: "row",
    fontSize: 20,
    alignItems: "center",
    fontFamily:'PoppinsSemi',

    //marginBottom: 5,
    //marginTop: 5,
    //border: "1px solid #000000"
    //marginLeft: 10,
    //marginRight: 10,
  };

  const getStatusClassName = (status: string) => {
    switch (status) {
      case "Pending":
      case "Assigned":
        return "flash-yellow-animation"; // Apply flashing yellow for Pending or Assigned
      case "Ongoing":
        return "flash-navy-animation";
      default:
        return ""; // No special animation class for other statuses
    }
  };

  const getIdColumnStyle = (status: string): React.CSSProperties => {
    let statusColor = "blue"; // Default background color

    if (status === "Pending") {
      statusColor = "#FFCC00"; // Darker yellow
    } else if (status === "Assigned") {
      statusColor = "#FFCC00"; // Darker yellow
    } else if (status === "Scheduled") {
      statusColor = "#B700ff";
    } else if (status === "Ongoing") {
      statusColor = activeColor;
    } else if (status === "Complete") {
      statusColor = "#00FF70";
    } else if (status === "Canceled") {
      statusColor = "red";
    }

    return {
      //flexBasis: columnWidths.status,
      textAlign: "center",
      backgroundColor: 'black',
      width: "70%",
      color: "white",
      fontSize: "14px",
      fontFamily: "PoppinsBold",
      border: `2px solid ${statusColor}`,
      paddingTop: 5,
      paddingBottom: 5,
      //borderRadius: 7,
    };
  };

  const columnStyles = (width: string): React.CSSProperties => ({
    flexBasis: width,
    display: "flex",
    flexDirection: "row",
    //backgroundColor: "pink",
    padding: "10px",
    fontSize: "14px",
    color: "black",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
  });

  return (
    <div style={scrollableListContainerStyles}>
      <li key={order.orderID} style={rowStyles}>
        <div style={columnStyles(columnWidths.id)}>
          <span>#{order.orderID}</span>
        </div>
        <div style={columnStyles(columnWidths.customer)}>
          <span>{order.orderedBy}</span>
        </div>
        <div style={columnStyles(columnWidths.provider)}>
          {order.provider ? (
            <span>{order.provider}</span>
          ) : (
            <span>No provider assigned</span>
          )}
        </div>

        <div style={columnStyles(columnWidths.area)}>
          <span>{order.address.zipCodeAddress}</span>
        </div>
        <div style={columnStyles(columnWidths.serviceDateTime)}>
          <span>
            {formatEpochDate(order.dateOfService)} / {formatEpochTime(order.dateOfService)}
          </span>
        </div>

        <div style={columnStyles(columnWidths.serviceTypePrice)}>
          <span>
            {order.service}&nbsp;${order.orderPrice}
          </span>
        </div>

        <div style={{ flexBasis: columnWidths.status,display:'flex',justifyContent:'center', }}>
          <div
            className={getStatusClassName(order.orderStatus)}
            style={getIdColumnStyle(order.orderStatus)}
          >
            <span>{order.orderStatus}</span>
          </div>
        </div>

        <div style={columnStyles(columnWidths.details)}>
          <button
            onClick={toggleOrderModal}
            style={{
              fontSize: "16px",
              color: "white", // Text color
              fontFamily: "PoppinsLight",
              backgroundColor: 'black', // Background color
              borderRadius: "5px", // Make it round
              padding: "5px 10px", // Padding for sizing (adjust as needed)
              border: "none", // Remove default button border
              cursor: "pointer", // Change cursor to pointer on hover
              outline: "none", // Remove focus outline
              //marginRight: 40,
            }}
          >
            Details
          </button>
        </div>
      </li>
      {isOrderModalOpen && (
        <OrderModal order={order} closeModal={toggleOrderModal} />
      )}
    </div>
  );
};

/*

  const toggleExpand = () => {
    if (!isDropdownOpen) {
      // setExpanded(!expanded);
    }
  };

  const handleOptionClick = (option: string) => {
    // Handle the selected option (e.g., reschedule or cancel)
    console.log(`Selected option: ${option}`);
    // Close the dropdown menu
    setIsDropdownOpen(false);
  };

  const handleDispatchClick = (event: React.MouseEvent) => {
    // Prevent the click event from propagating to the row element
    event.stopPropagation();
    toggleModal();
  };

  const handleDropDownClick = (event: React.MouseEvent) => {
    // Prevent the click event from propagating to the row element
    event.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };


 const [expanded, setExpanded] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(order.orderStatus);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);



const DispatchButton: React.FC<{
  isOpen: boolean;
  handleDispatchClick: (event: React.MouseEvent) => void;
}> = ({ isOpen, handleDispatchClick }) => {
  return (
    <div style={{ position: "relative", marginRight: 10 }}>
      <button onClick={handleDispatchClick} style={{}}>
        Dispatch
      </button>
    </div>
  );
};

{expanded && (
  <div style={styles.expandedDetails}>
    <div style={styles.leftPart}>
      <span>Address Details:</span>
      <br />
      <span>{order.address.streetAddress}</span>
      <br />
      <span>{order.address.stateAddress}</span>
      <br />
      <span>{order.address.zipCodeAddress}</span>
    </div>
    <div style={styles.rightPart}>
      <span>Order Placed On:</span>
      <br />
      <span>{order.dateOrderPlaced}</span>
    </div>
    <div style={styles.rightPart}>
      <span>Drop Off Preference:</span>
      <br />
      <span>{order.dropOffPreference}</span>
    </div>
  </div>
)}
*/

export default OrderRow;
