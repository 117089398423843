import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { SET_PROVIDER_DETAILS, SET_LOADING_PROVIDER } from './types';
import { db } from './../../../../App';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { RootState } from '../reducers';

export const fetchProviderDetails = (providerId: string): ThunkAction<void, RootState, unknown, AnyAction> => 
  async (dispatch) => {
    dispatch({ type: SET_LOADING_PROVIDER, payload: true }); // Start loading

    const providerRef = doc(db, 'Providers', providerId);

    try {
      const providerDoc = await getDoc(providerRef);

      if (providerDoc.exists()) {
        let data = providerDoc.data();
        if (data?.createdAt) {
          data.createdAt = data.createdAt.toDate().toISOString();
        }
        console.log("Provider data fetched:", data);
        dispatch({
          type: SET_PROVIDER_DETAILS,
          payload: data,
        });
      } else {
        console.log("Provider document does not exist.");
      }
    } catch (error) {
      console.error("Error fetching provider data:", error);
    } finally {
      dispatch({ type: SET_LOADING_PROVIDER, payload: false }); // End loading regardless of success or failure
    }

    // Set up real-time updates with onSnapshot
    const unsubscribe = onSnapshot(providerRef, (doc) => {
      if (doc.exists()) {
        let updatedData = doc.data();
        if (updatedData?.createdAt) {
          updatedData.createdAt = updatedData.createdAt.toDate().toISOString();
        }
        console.log("Provider data updated:", updatedData);
        dispatch({
          type: SET_PROVIDER_DETAILS,
          payload: updatedData,
        });
      }
    }, (error) => {
      console.error("Error listening to provider updates:", error);
    });

    // Optionally, return the unsubscribe function if you need to stop listening at some point
    return unsubscribe;
  };
