import React, { useState } from 'react';
import { db } from '../../App';
import { doc, setDoc, collection, addDoc, getDoc, updateDoc, getDocs, query, where } from 'firebase/firestore'; // Import necessary Firestore functions
import { UserData } from '.././Types';
import { update } from 'firebase/database';

interface AssignZipCodeProps {
  zipCode: string;
  userData: UserData; // The selected provider's user data
  onClose: () => void;
  updateAssignments: (user: UserData) => void;
  fetchZipCodes: () => void; // Add fetchZipCodes prop
}

const AssignZipCode: React.FC<AssignZipCodeProps> = ({ zipCode, onClose ,userData,updateAssignments ,fetchZipCodes}) => {
  const [priority, setPriority] = useState(1); // Default priority is 1
  const [rate, setRate] = useState(0); // Default rate is 0

  const handleAssignClick = () => {
    // Call the handleAssign function with the specified parameters
    handleAssign(zipCode, userData, priority, rate);
    fetchZipCodes();
    updateAssignments(userData);

    onClose();
  };
  
  async function handleAssign(zipCode:any, userData:any, priority:any, rate:any) {
    // Function to update existing priorities and shift them
    function updatePriorities(existingPriorities:any, newPriority:any) {
      const updatedPriorities = [];
      let priorityTaken = false;
  
      for (const existingPriority of existingPriorities) {
        if (existingPriority === newPriority) {
          updatedPriorities.push(existingPriority + 1);
          priorityTaken = true;
        } else if (existingPriority > newPriority && !priorityTaken) {
          updatedPriorities.push(newPriority);
          updatedPriorities.push(existingPriority);
          priorityTaken = true;
        } else {
          updatedPriorities.push(existingPriority);
        }
      }
  
      if (!priorityTaken) {
        updatedPriorities.push(newPriority);
      }
  
      return updatedPriorities;
    }
  
    try {
      const zipCodesCollection = collection(db, 'ZipCodes');
  
      // Check if the zip code document exists
      const querySnapshot = await getDocs(
        query(zipCodesCollection, where('ZipCode', '==', zipCode))
      );
  
      if (querySnapshot.size === 0) {
        console.error(`Zip code ${zipCode} does not exist.`);
        return;
      }
  
      // Assuming there's only one document with the same ZipCode value,
      // you can directly access the first document from the query result
      const zipCodeDoc = querySnapshot.docs[0];
  
      const zipCodeData = zipCodeDoc.data();
  
      // Check if AssignedProviders map exists
      if (!zipCodeData.AssignedProviders) {
        // Create AssignedProviders map if it doesn't exist
        zipCodeData.AssignedProviders = {};
      }
  
      const existingPriorities = Object.keys(zipCodeData.AssignedProviders).map(Number);
  
      // Check if the chosen priority already exists
      if (existingPriorities.includes(priority)) {
        // Update existing priorities and shift them
        const updatedPriorities = updatePriorities(existingPriorities, priority);
  
        // Create a new assignment with the updated priorities
        const newAssignment = {
          UID: userData.uid,
          Rate: rate.toString(),
        };
  
        const updatedAssignedProviders = {};
  
        updatedPriorities.forEach((updatedPriority, index) => {
            //@ts-ignore
          updatedAssignedProviders[updatedPriority] = zipCodeData.AssignedProviders[existingPriorities[index]];
        });
  
        // Add the new assignment
                    //@ts-ignore
        updatedAssignedProviders[priority] = newAssignment;
  
        // Update the AssignedProviders map with the new assignments
        zipCodeData.AssignedProviders = updatedAssignedProviders;
      } else {
        // Create a new assignment with the specified priority
        zipCodeData.AssignedProviders[priority] = {
          UID: userData.uid,
          Rate: rate.toString(),
        };
      }
  
      // Update the ZipCode document with the modified AssignedProviders map
      await setDoc(zipCodeDoc.ref, {
        AssignedProviders: zipCodeData.AssignedProviders,
      }, { merge: true });
  
    } catch (error) {
      console.error('Error assigning provider to zip code:', error);
    }
  }
  
  

  return (
    <div className="assign-zipcode">
      <h3 style={{color:'black'}}>Assign Provider to Zip Code: {zipCode}</h3>
      <label style={{color:'black'}}>
        Priority:
        <input
          type="number"
          value={priority}
          onChange={(e) => setPriority(Number(e.target.value))}
        />
      </label>
      <label style={{color:'black'}}>
        Rate:
        <input
          type="number"
          value={rate}
          onChange={(e) => setRate(Number(e.target.value))}
        />
      </label>
      <button style={styles.buttonSmall} onClick={handleAssignClick}>Assign</button>
      <button style={styles.buttonSmall} onClick={onClose}>Cancel</button>
    </div>
  );
};



interface Styles {
  [key: string]: React.CSSProperties;
}
const styles: Styles = {

  buttonSmall: {
    padding: '10px 16px', // Increases padding to make the button larger
    fontSize: '12px', // Increases the font size
    cursor: 'pointer', // Changes the cursor on hover
    marginTop:10,
    marginLeft:10,
    color: 'white',
    //width:100,
    borderWidth: 0,
    borderRadius: 25,
    backgroundColor: 'black',
  },

};

export default AssignZipCode;


