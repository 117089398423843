import React, { useState } from "react";
import { Assignment, UserData } from ".././Types";
import ZipCodeSelector from "./ZipCodeSelector";
import "./ZipCodeManagementModal.css";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../App";
import { doc, getDoc } from "firebase/firestore";

interface ZipCodeManagementModalProps {
  isOpen: boolean;
  onClose: () => void;
  userData: UserData; // The selected provider's user data
  fetchUsers: () => void;
}

const ZipCodeManagementModal: React.FC<ZipCodeManagementModalProps> = ({
  isOpen,
  onClose,
  userData,
  fetchUsers,
}) => {
  const [editedAssignments, setEditedAssignments] = useState(
    userData.assignments
  );
  
  const [showZipCodeSelector, setShowZipCodeSelector] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // State to track if editing mode is active
  const [oldPriority, setOldPriority] = useState(0); // State to track if editing mode is active
  const [isLoading, setIsLoading] = useState(false);

  const [editedAssignment, setEditedAssignment] = useState<Assignment | null>(
    null
  ); // State to store the assignment being edited

  const enterEditMode = (assignment: Assignment) => {
    setEditedAssignment(assignment);
    setOldPriority(assignment.priority);
    setIsEditing(true);
  };

  const fetchAssignments = async (user: UserData) => {
    try {
      const zipCodesCollection = collection(db, "ZipCodes");
      const zipCodeQuery = query(zipCodesCollection);
      const querySnapshot = await getDocs(zipCodeQuery);

      const assignments = [];

      for (const docSnap of querySnapshot.docs) {
        const data = docSnap.data();
        const assignedProviders = data.AssignedProviders || {};

        for (const priority in assignedProviders) {
          // Check for UID instead of Email
          if (assignedProviders[priority].UID === user.uid) {
            assignments.push({
              id: docSnap.id,
              ZipCode: data.ZipCode,
              priority: parseInt(priority),
              rate: parseInt(assignedProviders[priority].Rate),
            });
          }
        }
      }

      // Set state and perform any additional actions
      console.log(assignments);
      setEditedAssignments(assignments);
      userData.assignments = assignments;
      fetchUsers();
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching assignments:", error);
      throw error; // Handle the error as needed in your component
    }
  };

  const saveEditedAssignment = async (
    updatedPriority: number,
    updatedRate: number
  ) => {
    setIsLoading(true);
    if (editedAssignment) {
      try {
        setIsEditing(false);
        setEditedAssignment(null);

        // Get the document reference based on the editedAssignment's ID
        const zipCodeDocRef = doc(db, "ZipCodes", editedAssignment.id);

        // Fetch the document data
        const zipCodeDocSnap = await getDoc(zipCodeDocRef);

        if (zipCodeDocSnap.exists()) {
          // Get the existing AssignedProviders map from the document data
          const assignedProviders =
            zipCodeDocSnap.data()?.AssignedProviders || {};

          // Delete the old priority key if needed
          if (updatedPriority.toString() !== oldPriority.toString()) {
            // Temporarily store the assignment at the new priority
            const tempAssignment =
              assignedProviders[updatedPriority.toString()];

            // Update the priority and rate of the edited assignment
            assignedProviders[updatedPriority.toString()] = {
              UID: userData.uid, // Use UID instead of Email
              Rate: updatedRate.toString(),
            };

            // Move the previous assignment at updatedPriority to oldPriority
            assignedProviders[oldPriority.toString()] = tempAssignment;
          } else {
            // Update the priority and rate of the edited assignment
            assignedProviders[updatedPriority.toString()] = {
              UID: userData.uid, // Use UID instead of Email
              Rate: updatedRate.toString(),
            };
          }

          // Update the AssignedProviders map in the document
          await updateDoc(zipCodeDocRef, {
            AssignedProviders: assignedProviders,
          });
          fetchAssignments(userData);
        }
      } catch (error) {
        console.error("Error saving edited assignment:", error);
      }
    }
  };

  const handleDeleteAssignment = async (id: string, zipCode: string) => {
    try {
      // Update the assignment in userData by filtering out the assignment with the specified ID
      //@ts-ignore
      const updatedAssignments = editedAssignments.filter(
        (assignment) => assignment.id !== id
      );
      setEditedAssignments(updatedAssignments);

      // Query the ZipCodes collection to find the document with the matching ZipCode
      const zipCodesCollection = collection(db, "ZipCodes");
      const zipCodeQuery = query(
        zipCodesCollection,
        where("ZipCode", "==", zipCode)
      );
      const querySnapshot = await getDocs(zipCodeQuery);

      if (querySnapshot.empty) {
        console.error(`Zip code ${zipCode} document does not exist.`);
        return;
      }

      // Assuming there's only one document with the same ZipCode value,
      // you can directly access the first document from the query result
      const zipCodeDoc = querySnapshot.docs[0].ref;

      // Get the existing AssignedProviders map from the ZipCode document
      const zipCodeData = (await getDoc(zipCodeDoc)).data();
      //@ts-ignore
      const assignedProviders = zipCodeData.AssignedProviders || {};

      // Find the priority of the assignment to delete based on userData.email
      let deletedPriority = null;
      for (const priority in assignedProviders) {
        if (assignedProviders[priority].UID === userData.uid) {
          deletedPriority = priority;
          break;
        }
      }

      if (deletedPriority) {
        // Delete the assignment from AssignedProviders
        delete assignedProviders[deletedPriority];

        // Update the priorities of the remaining providers
        const updatedAssignedProviders = {};
        let newPriority = 1;
        for (const priority in assignedProviders) {
          //@ts-ignore
          updatedAssignedProviders[newPriority.toString()] =
            assignedProviders[priority];
          newPriority++;
        }

        // Update the AssignedProviders map in the ZipCode document
        await updateDoc(zipCodeDoc, {
          AssignedProviders: updatedAssignedProviders,
        });
      }
      fetchAssignments(userData);
    } catch (error) {
      console.error("Error deleting assignment:", error);
    }
  };

  const handleAddAssignment = () => {
    fetchAssignments(userData);
    setShowZipCodeSelector(true);
  };

  const handleSave = () => {
    fetchAssignments(userData);
    onClose();
  };

  return (
    <div className={`dispatch-modalM ${isOpen ? "open" : ""}`}>
      <div
        className={`modal-overlayM ${isOpen ? "open-overlay" : ""}`}
        onClick={onClose}
      ></div>
      <div
        style={{ backgroundColor: "white", minWidth: 500 }}
        className={`modal-contentM ${isOpen ? "open-content" : ""}`}
      >
        {isLoading ? (
          <p>Loading...</p>
        ) : showZipCodeSelector ? (
          <ZipCodeSelector
            closeSelector={() => setShowZipCodeSelector(false)}
            userData={userData}
            updateAssignments={fetchAssignments}
            onClose={() => setShowZipCodeSelector(false)}
          />
        ) : isEditing ? (
          <div>
            <h2 style={{ color: "black" }}>Edit Assignment</h2>
            <button
              onClick={onClose}
              style={{
                position: "absolute",
                color: "black",
                top: "10px",
                right: "10px",
                border: "none",
                background: "none",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              X
            </button>
            <label style={{ color: "black" }}>
              Priority:
              <input
                type="number"
                value={editedAssignment?.priority}
                onChange={(e) => {
                  const updatedPriority = parseInt(e.target.value);
                  //@ts-ignore
                  setEditedAssignment((prevAssignment) => ({
                    ...prevAssignment,
                    priority: updatedPriority,
                  }));
                }}
              />
            </label>
            <label style={{ color: "black" }}>
              Rate:
              <input
                type="number"
                value={editedAssignment?.rate}
                onChange={(e) => {
                  const updatedRate = parseInt(e.target.value);
                  //@ts-ignore
                  setEditedAssignment((prevAssignment) => ({
                    ...prevAssignment,
                    rate: updatedRate,
                  }));
                }}
              />
            </label>
            <button
              style={styles.buttonSmall}
              onClick={() =>
                saveEditedAssignment(
                  editedAssignment?.priority || 0,
                  editedAssignment?.rate || 0
                )
              }
            >
              Save
            </button>
            <button
              style={styles.buttonSmall}
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </button>
          </div>
        ) : (
          <div>
            <h2 style={{ color: "black" }}>Manage Assignments</h2>
            <button
              onClick={onClose}
              style={{
                position: "absolute",
                color: "black",
                top: "10px",
                right: "10px",
                border: "none",
                background: "none",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              X
            </button>
            {/*@ts-ignore*/}
            {editedAssignments.length === 0 ? (
              <p style={{ color: "black" }}>No assignments available</p>
            ) : (
              <ul
                style={{
                  padding: 30,
                  backgroundColor: "#f5f5f5",
                  borderRadius: "25px",
                  margin: 10,
                }}
              >
                {/*@ts-ignore*/}
                {editedAssignments.map((assignment) => (
                  <li style={{ color: "black" }} key={assignment.id}>
                    Priority: {assignment.priority}, Zip Code:{" "}
                    {assignment.ZipCode}, Rate: {assignment.rate}
                    <div style={{ textAlign: "right", marginTop: 10 }}>
                      <button
                        style={styles.buttonSmall}
                        onClick={() => enterEditMode(assignment)}
                      >
                        Edit
                      </button>
                      <button
                        style={styles.buttonSmall}
                        onClick={() =>
                          handleDeleteAssignment(
                            assignment.id,
                            assignment.ZipCode
                          )
                        }
                      >
                        Delete
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}

            
            <div style={{ textAlign: "center" }}>
              <button style={styles.button} onClick={handleAddAssignment}>
                Add
              </button>
            </div>
                      
            <div style={{ textAlign: "right" }}>
              <button style={styles.button} onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ZipCodeManagementModal;

interface Styles {
  [key: string]: React.CSSProperties;
}
const styles: Styles = {
  button: {
    padding: "10px 20px", // Increases padding to make the button larger
    fontSize: "20px", // Increases the font size
    //margin: '10px 10px', // Adds some margin above the button
    cursor: "pointer", // Changes the cursor on hover
    color: "white",
    //width:100,
    borderWidth: 0,
    borderRadius: 25,
    backgroundColor: "black",
  },
  buttonSmall: {
    padding: "10px 16px", // Increases padding to make the button larger
    fontSize: "12px", // Increases the font size
    cursor: "pointer", // Changes the cursor on hover
    marginTop: 10,
    marginLeft: 10,
    color: "white",
    //width:100,
    borderWidth: 0,
    borderRadius: 25,
    backgroundColor: "black",
  },
};
