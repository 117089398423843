import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { browserSessionPersistence, getAuth, onAuthStateChanged, setPersistence } from "firebase/auth";
import Sidebar from "./SideBar";
import { db } from "../../App";
import { collection, onSnapshot } from "firebase/firestore"; // Import Firestore methods
import Orders from "./Orders";
import Map from ".././Map";
import Assignments from "./Assignments";
import AssignmentZipCode from "./AssignmentsZipCode";
import Pricing from "./Pricing";
import Dashboard from "./Dashboard";
import Providers from "./Providers";
import Marketing from "./Marketing";
import Customers from "./Customers";
import Prospects from "./Prospects";
import { useDispatch, useSelector } from "react-redux"; // Import useDispatch
import { setOrders } from "../../store/ordersReducer"; // Adjust the import path as necessary
import { setProviders } from "../../store/providersReducer";
import { RootState } from "../../store/redux";
import { setCustomers } from "../../store/customersReducer";
import GradientContainer from "./GradientContainer";
import TitleBar from ".././TitleBar";
import { setNotifications } from "../../store/notificationsReducer";
import { setAssignments } from "../../store/assignmentsReducer";
import { setProspects } from "../../store/prospectsReducer";
import { setPricing } from "../../store/pricingReducer";
import { setCoupons } from "../../store/couponsReducer";
import Dispatch from "./Dispatch";

const Index: React.FC = () => {
  const navigate = useNavigate();
  const auth = getAuth();

  const dispatch = useDispatch(); // Use useDispatch to get the dispatch function

  const orders = useSelector((state: RootState) => state.orders.orders);

  const [selectedTab, setSelectedTab] = useState('Orders'); // Default selected tab
  
  
  //const [dashboardView, setDashboardView] = useState('LIST'); // Default dashboard view
  //const [markers, setMarkers] = useState<google.maps.LatLngLiteral[]>([]);

  useEffect(() => {
    const auth = getAuth();
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        return onAuthStateChanged(auth, (user) => {
          if (user) {
            // User is signed in.
          } else {
            // No user is signed in.
            navigate("/login");
          }
        });
      })
      .catch((error) => {
        console.error("Persistence setting failed", error);
      });
  }, [navigate]);


  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "Orders"),
      (querySnapshot) => {
        const updatedOrders = querySnapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          //@ts-ignore Sort by orderID in descending order
          .sort((a, b) => b.orderID - a.orderID);

        dispatch(setOrders(updatedOrders)); // Dispatch action to update orders in Redux store
      }
    );

    return unsubscribe; // Clean up the subscription
  }, [dispatch]);


  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "Providers"),
      (querySnapshot) => {
        const updatedProviders = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          UID: doc.id,
        }));

        dispatch(setProviders(updatedProviders)); // Dispatch action to update providers in Redux store
      }
    );

    return unsubscribe; // Clean up the subscription
  }, [dispatch]);


  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "Users"), // Use "Users" collection
      (querySnapshot) => {
        const updatedCustomers = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          UID: doc.id,
        }));
        dispatch(setCustomers(updatedCustomers)); // Dispatch action to update customers in Redux store
      }
    );
  
    return unsubscribe; // Clean up the subscription
  }, [dispatch]);


  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "Assignments"),
      (querySnapshot) => {
        const updatedAssignments = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        dispatch(setAssignments(updatedAssignments)); // Dispatch action to update assignments in Redux store
      }
    );
  
    return unsubscribe; // Clean up the subscription
  }, [dispatch]);
  
  useEffect(() => {
    const pricingUnsub = onSnapshot(collection(db, "Pricing"), (querySnapshot) => {
      const pricingData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      dispatch(setPricing(pricingData));
    });
  
    const couponsUnsub = onSnapshot(collection(db, "Coupons"), (querySnapshot) => {
      const couponsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      dispatch(setCoupons(couponsData));
    });
  
    return () => {
      pricingUnsub();
      couponsUnsub();
    };
  }, [dispatch]);
  

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "Contacts"), // Listening to "Contacts" for prospects
      (querySnapshot) => {
        const updatedProspects = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          UID: doc.id,
        }));
        dispatch(setProspects(updatedProspects)); // Dispatch action to update prospects in Redux store
      }
    );
  
    return unsubscribe; // Clean up the subscription
  }, [dispatch]);
  
  useEffect(() => {
  const unsubscribe = onSnapshot(
    collection(db, "Notifications"),
    (querySnapshot) => {
      const updatedNotifications = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      dispatch(setNotifications(updatedNotifications)); // Dispatch action to update notifications in Redux store
    }
  );

  return unsubscribe; // Clean up the subscription
}, [dispatch]);
 


  {
    /*
  useEffect(() => {
    // Get user's current location using browser's geolocation API
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setMapCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  }, []);    */
  }

  const handleTabSelect = (tabName: string) => {
    setSelectedTab(tabName);
  };


  const defaultProps = {
    center: {
      lat: 33.7175,
      lng: -117.8311,
    },
    zoom: 11,
  };

  const renderMarkersAndPolygons = (map:any, maps:any, geoJsonData:any) => {
    // Assuming orders is accessible here, place markers as before
    orders.forEach((order) => {
      new maps.Marker({
        position: {
          lat: order.address.location.latitude,
          lng: order.address.location.longitude,
        },
        map,
        title: order.orderID,
      });
    });
  
    // Load GeoJSON ZIP code areas
    map.data.addGeoJson(geoJsonData);
  
    // Style the ZIP code areas
    map.data.setStyle((feature:any) => {
      const color = '#' + Math.floor(Math.random()*16777215).toString(16); // Random color, replace with logic if needed
      return {
        fillColor: color,
        strokeWeight: 1,
        fillOpacity: 0.5,
      };
    });
  };
  

  const tabContent: { [key: string]: JSX.Element } = {
    Orders: (
      <Orders
      //todos={todos}
      //renderMarkers={renderMarkers}
      //dashboardView={dashboardView}
      //handleDashboardViewChange={handleDashboardViewChange}
      //defaultProps={defaultProps}
      />
    ),
    /*
    Map: 
    <GradientContainer>
            <TitleBar title="Map" />

    <Map renderMarkersAndPolygons={renderMarkersAndPolygons} defaultProps={defaultProps} />
    </GradientContainer>
    ,*/
    Map: <AssignmentZipCode/>,
    Assignments: <Assignments />,

    Dispatch: <Dispatch />,
    Pricing: <Pricing />,
    Dashboard: <Dashboard />,
    Providers: <Providers />,
    Customers: <Customers />,
    Marketing: <Marketing />,
    "Prospects Registry": <Prospects />,

    // Add other tab content as needed
  };

  return (
    <div style={styles.container}>
      {/*<TopBar />*/}
      <div style={styles.contentContainer}>
        <Sidebar onTabSelect={handleTabSelect} selectedTab={selectedTab} />
        <div style={styles.content}>{tabContent[selectedTab]}</div>
      </div>
    </div>
  );
};

interface Styles {
  [key: string]: React.CSSProperties;
}

const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  contentContainer: {
    display: "flex",
    flex: 1,
  },
  dashboardTabs: {
    display: "flex",
    justifyContent: "center", // Center the buttons horizontally
    marginBottom: "10px", // Add some spacing below the buttons
  },
  tabButton: {
    border: "none", // Remove border
    fontSize: "15px",
    margin: "0 auto", // This will center the button horizontally
    display: "block", // Ensures the button takes the full width available
    padding: "5px 20px", // Add some padding so it looks nice
  },
  content: {
    flex: 1,
    //border: '2px solid red',
    //padding: '10px',
  },
};

export default Index;

/*

  const handleDashboardViewChange = (view: string) => {
    //setDashboardView(view);
  };


  const fetchPost = async () => {
       
    await getDocs(collection(db, "Orders"))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
                //@ts-ignore
            setTodos(newData);                
            console.log(newData);
            const newMarkers = newData.map((order) => ({
              //@ts-ignore  
              lat: order.address.location.latitude,
              //@ts-ignore  
              lng: order.address.location.longitude,
            }));
            console.log(newMarkers)

            setMarkers(newMarkers); // Set the markers state
        })
   
}

useEffect(()=>{
    fetchPost();
}, [])
*/
