// components/LoginForm.tsx
import React, { CSSProperties, useEffect, useState } from "react";
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import TopBar from "./TopBar";
import "./SignUpForm.css?v=1";
import { db } from "../App"; // Import your Firestore database instance
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import dripAutoImage from "../assets/login.png";
import { SERVER_URL } from "./Functions";
import GetApp from "./Admin/GetApp";

const SignUpForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [responseMessage, setResponseMessage] = useState(" ");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate(); // Initialize useNavigate
  const auth = getAuth();

  const isValidEmail = (email: any) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleRegister = async () => {
    if (!isValidEmail(email)) {
      setResponseMessage("Please enter a valid email address");
      setIsSubmitting(false);
      return;
    }

    if (password.length < 6 || confirmPassword.length < 6) {
      setResponseMessage("Password must be at least 6 characters long");
      setIsSubmitting(false);
      return;
    }

    if (password !== confirmPassword) {
      console.error("Passwords do not match");
      setResponseMessage("Passwords do not match");
      return;
    }

    setIsSubmitting(true);
    setResponseMessage(" ");

    try {
      // Register the user

      if (auth.currentUser) {
        await auth.signOut(); // Log out the current user
      }

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const authNetId = await createAuthorizeNetProvider(email);

      // Redirect after successful registration
      await addProviderToFirestore(userCredential.user.uid, authNetId);

      await signInWithEmailAndPassword(auth, email, password);

      setIsSubmitting(false); // Stop the loading spinner

      navigate("/indexProv"); // Adjust the route as per your app's requirement
    } catch (error) {
      console.error("Registration error:", error);
      //@ts-ignore
      setResponseMessage(error.message); // Display the error message to the user
      setIsSubmitting(false); // Stop the loading spinner
    }
  };

  const addProviderToFirestore = async (userId: string, authNetId: string) => {
    try {
      // Reference to the document in the 'Providers' collection with the user's UID
      const providerDocRef = doc(db, "Providers", userId);

      // Set data in this document
      await setDoc(providerDocRef, {
        createdAt: serverTimestamp(), // Firestore server timestamp
        authNetId,  // Add authNetId field
        // ... any other fields you want to initialize
      });

      console.log("Provider account created in Firestore with UID:", userId);
    } catch (error) {
      console.error("Error when creating provider account:", error);
      throw error; // Re-throw the error for further handling
    }
  };

  const createAuthorizeNetProvider = async (email: string) => {
    try {
      const response = await fetch(`${SERVER_URL}/create-authorizenet-provider`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
  
      if (!response.ok) throw new Error('Failed to create Authorize.Net provider');
  
      const { customerProfileId } = await response.json();
      return customerProfileId;
    } catch (error) {
      console.error('Error creating Authorize.Net provider:', error);
      throw error;
    }
  };
  

  /*
  useEffect(() => {
    // Check if the user is already authenticated and redirect to index page
    const auth = getAuth();
    const currentUser = auth.currentUser;
    console.log('this'+currentUser)
    if (currentUser) {
      navigate('/index');
    }
  }, [navigate]); */

  const navigateToLogin = () => {
    navigate("/login"); // Change to your login route
  };

  const handleLoginClick = () => {
    navigate("/"); // Use the path for your ContactForm page
  };


  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  if (isMobile) {
    return <GetApp />;
  }

  return (
    <div style={styles.mainContainer}>
      <div style={styles.leftContainer}>
        <img src={dripAutoImage} alt="Drip Auto" style={styles.dripAutoImage} />
        <div style={styles.centeredContent}>
          <div
            onClick={handleLoginClick}
            style={{ fontSize: 20, fontWeight: 600 }}
          >
            Go Home
          </div>
        </div>
        <p style={styles.copyrightText}>
          Copyright © 2019 - 2024 Drip-Auto Care inc. All Rights Reserved.
        </p>
      </div>

      <div style={styles.rightContainer}>
        <div style={styles.formContainer}>
          <h2 style={styles.loginTitle}>Sign Up</h2>
          <p style={styles.leftAlign}>
            Create your account to get benefits of the Rinz
          </p>
          <label htmlFor="email" style={styles.labelStyle}>
            Email
          </label>
          <input
            style={styles.inputButton}
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="password" style={styles.labelStyle}>
            Password
          </label>
          <input
            style={styles.inputButton}
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label htmlFor="confirm password" style={styles.labelStyle}>
            Confirm Password
          </label>

          <input
            style={styles.inputButton}
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <p style={styles.responseMessage}>{responseMessage}</p>
          <p style={styles.terms}>
            Accept Our Terms & Conditions & Privacy Policy
          </p>

          <button
            style={{ ...styles.inputButton, ...styles.button }}
            onClick={handleRegister}
            disabled={isSubmitting}
            className={isSubmitting ? "loading" : ""}
          >
            {isSubmitting ? <div className="spinner-small"></div> : "Sign Up"}
          </button>
          <p onClick={navigateToLogin} style={styles.centerText}>
            Already have an account?{" "}
            <span style={{ color: "#007ae4", cursor: "pointer" }}>Sign in</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;

const styles: { [key: string]: CSSProperties } = {
  mainContainer: {
    display: "flex",
    flex: 1,
    height: "100vh",
  },

  labelStyle: {
    display: "block",
    marginBottom: "-15px",
    marginLeft: "5px",
    fontSize: "16px",
    fontWeight: 600,
  },
  rightContainer: {
    flex: 1,
    padding: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
  },
  leftContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    padding: "0px",
    position: "relative",
  },
  dripAutoImage: {
    //width: "100%", // Image occupies 90% of its parent container
    maxWidth: "70%", // Maximum width to prevent the image from being too large
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
    margin: "0 auto 20px auto", // Center the image horizontally
  },
  centeredContent: {
    textAlign: "center",
    fontSize: "36px",
    cursor: "pointer",
    width: "100%",
    position: "relative",
    padding: "10px 0",
  },

  loginTitle: {
    alignSelf: "flex-start",
    fontSize: 36,
    fontWeight: 800,
  },
  leftAlign: {
    alignSelf: "flex-start",
    marginBottom: "50px",
    fontWeight: 500,
    color: "#9c9c9c",
  },
  centerText: {
    width: "100%",
    textAlign: "center",
    cursor: "pointer",
    fontWeight: 500,
    color: "#9c9c9c",
  },
  input: {
    margin: "25px 0",
    width: "100%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "10px",
    fontSize: "16px",
  },
  terms: {
    alignSelf: "flex-start",
    textAlign: "center",
    fontWeight: 500,
  },
  responseMessage: {
    color: "red",
    textAlign: "center",
  },
  inputButton: {
    margin: "25px 0",
    width: "100%",
    padding: "15px",
    fontFamily: "Roboto-Medium",
    border: "2px solid #e8edf3",
    backgroundColor: "#fafcfe",
    borderRadius: 10,
    //borderRadius: "10px",
    fontSize: "18px",
  },
  button: {
    backgroundColor: "#007ae4", // Custom blue color
    color: "white",
    cursor: "pointer",
    margin: "25px 0",
    width: "100%",
    padding: "15px",
    border: "0px solid #ccc",
    fontSize: "18px",
    fontWeight: 800,
    borderRadius: "40px", // Remove rounded corners
  },

  formContainer: {
    flex: 1,
    maxWidth: "500px",
  },
  copyrightText: {
    textAlign: "center",
    position: "absolute",
    bottom: "10px",
    width: "100%",
    fontWeight: 500,
  },
};
