export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const ADD_ORDERS = 'ADD_ORDERS';
export const ADD_CUSTOMERS = 'ADD_CUSTOMERS';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';

export const SET_LOADING_ORDERS = 'SET_LOADING_ORDERS';
export const SET_LOADING_CUSTOMERS = 'SET_LOADING_CUSTOMERS';
export const SET_LOADING_INVOICES = 'SET_LOADING_INVOICES';
export const SET_LOADING_PROVIDER = 'SET_LOADING_PROVIDER';


export const SET_PROVIDER_DETAILS = 'SET_PROVIDER_DETAILS';

export const FETCH_INVOICES = 'FETCH_INVOICES';
export const ADD_INVOICES = 'ADD_INVOICES';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';

export const FETCH_ZONES = 'FETCH_ZONES';
export const FETCH_REGIONS = 'FETCH_REGIONS';

export interface Customer {
    uid: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    profileImage: string;
    dateOfBirth: string;
    createdAt: string;
    gender: string;
    stripeCustomerId: string;
    verified: boolean;
    addresses: {
        location: { latitude: number; longitude: number; latitudeDelta?: number; longitudeDelta?: number };
        stateAddress: string;
        cityAddress: string;
        streetAddress: string;
        zipCodeAddress: string;
    }[];
    vehicles: {
        model: string;
        color: string;
        make: string;
        imageSource: string;
        vinNumber: string;
    }[];
}



export interface Order {
    id: string;
    dateOrderPlaced: number;
    address: {
      stateAddress: string;
      cityAddress: string;
      location: { latitude: number; longitude: number };
      streetAddress: string;
      zipCodeAddress: string;
    };
    orderPrice: string;
    service: string;
    orderStatus: string;
    orderedBy: string;  // Added orderedBy field

    //these are conditionally available depending on the state, the data above is required for an order 
    regionId: string;
    currentZoneId: string;
    onRouteToOngoing: number;
    placedToAccepted: number;
    acceptedToOnRoute: number;
    eta: number;
    providerRate: number;
    regionalFactorMultiplier: number;
    distanceOnRoute: number;
    distanceOngoing: number;
    rating: number;
    //admin only should load this
    providerPriority: number;
    elevate: boolean;
    payment: string;
    inspectImageUrls: [];
  }

  
  export interface Invoice {
    id: string;
    orderId: string;
    providerId: string;
    customerId: string;
    orderPrice: string;
    finalInvoiceAmount: number;
    providerAmount: number;
    orderZipCode: string;

    orderDateTimePlaced: number;
    orderDateTimeService: number;
    orderDateTimeCompleted: number;
    timestamp: number;
    timestampSent:number;

    orderService: string;
    placedToAccepted: number;
    acceptedToOnRoute: number;
    onRouteToOngoing: number;
    distanceOnRoute: number;
    distanceOngoing: number;
    couponValue: number;
    subscriptionDiscount: number;
    onDemandSurcharge: number;
    regionalFactorMultiplier: number;
    providerRate: number;
    providerPriority: number;
    invoiceStatus: string; // Add this line if invoiceStatus is a valid field
    alerts?: string; // Add this line for the alerts field
    orderStatus?:string;
}


  export interface Action {
    type: string;
    payload: any;
  }