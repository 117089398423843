import React, { CSSProperties, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "./store/reducers"; // Adjust the path as per your project
import { SERVER_URL } from "../Functions";

interface PaymentMethod {
  paymentProfileId: string;
  cardNumber: string;
  expirationDate: string;
  billingAddress: any;
}

interface SavedCardsProps {
  mode?: string;
  onCardSelect?: (card: PaymentMethod | null) => void;
}

const SavedCards: React.FC<SavedCardsProps> = ({
  mode = "default",
  onCardSelect,
}) => {
  const providerDetails = useSelector(
    (state: RootState) => state.provider.details
  );
  const [savedCards, setSavedCards] = useState<PaymentMethod[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedCard, setSelectedCard] = useState<PaymentMethod | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const fetchSavedCards = async (authNetId: string) => {
    try {
      const response = await fetch(`${SERVER_URL}/fetch_saved_cards`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ authNetId }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch saved cards");
      }
      return data.paymentProfiles;
    } catch (error) {
      const err = error as Error; // Cast the error to type Error
      console.error("Error fetching saved cards:", err.message);
      throw err;
    }
  };

  useEffect(() => {
    const initializeSavedCards = async () => {
      if (providerDetails.authNetId) {
        try {
          const cards = await fetchSavedCards(providerDetails.authNetId);

          if (cards.length === 0) {
            // No cards found, display message in UI but no alert needed
            setSavedCards([]);
          } else {
            // Cards found, set them in the state
            setSavedCards(cards);
          }
        } catch (error) {
          const err = error as Error;
          // Trigger an alert only if there is an actual error in the fetch
          alert(`Failed to load saved cards: ${err.message}`);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    initializeSavedCards();
  }, [providerDetails.authNetId]);

  const handleSaveCard = async (
    cardNumber: string,
    expDate: string,
    cvv: string,
    billingAddress: any
  ) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${SERVER_URL}/save_card`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          authNetId: providerDetails.authNetId,
          cardNumber,
          expirationDate: expDate,
          cardCode: cvv,
          billingAddress,
        }),
      });

      if (!response.ok) {
        const errorMsg = await response.text();
        throw new Error(errorMsg);
      }

      alert("Your card has been saved.");
      setModalVisible(false);

      const updatedCards = await fetchSavedCards(providerDetails.authNetId);
      setSavedCards(updatedCards);
    } catch (error) {
      const err = error as Error;
      alert(`Unable to save card: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardSelect = (card: PaymentMethod) => {
    setSelectedCard(card);
    onCardSelect && onCardSelect(card);
  };

  return (
    <div>
      {isLoading ? (
        <div style={styles.loader}>Loading...</div>
      ) : savedCards.length === 0 ? (
        <p>No saved cards available.</p>
      ) : (
        savedCards.map((paymentMethod, index) => (
          <div
            key={index}
            style={{
              ...styles.savedCard,
              ...(mode === "alternative" && selectedCard === paymentMethod
                ? styles.selectedCard
                : {}),
            }}
            onClick={() =>
              mode === "alternative" && handleCardSelect(paymentMethod)
            }
          >
            <p style={styles.cardBrand}>
              Card Ending in {paymentMethod.cardNumber.slice(-4)}
            </p>
            <p style={styles.cardDetails}>
              Expires {paymentMethod.expirationDate}
            </p>
          </div>
        ))
      )}

      {modalVisible && (
        <CardInputModal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          onSave={handleSaveCard}
        />
      )}

<div style={styles.buttonContainer}>
        <button
          style={styles.addCardButton}
          onClick={() => setModalVisible(true)}
        >
          Add Card
        </button>
      </div>
    </div>
  );
};

// Inline CardInputModal component
const CardInputModal: React.FC<{
  visible: boolean;
  onClose: () => void;
  onSave: (
    cardNumber: string,
    expDate: string,
    cvv: string,
    billingAddress: any
  ) => void;
}> = ({ visible, onClose, onSave }) => {
  const [cardNumber, setCardNumber] = useState("");
  const [expDate, setExpDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [billingAddress, setBillingAddress] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

  const [isFormValid, setIsFormValid] = useState(false);

  // Validation error states
  const [errors, setErrors] = useState({
    cardNumber: "",
    expDate: "",
    cvv: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

  const [touched, setTouched] = useState({
    cardNumber: false,
    expDate: false,
    cvv: false,
    firstName: false,
    lastName: false,
    address: false,
    city: false,
    state: false,
    zip: false,
    country: false,
  });

  const validateCardNumber = (value: string) => {
    const cardNumberRegex = /^\d{16}$/;
    return cardNumberRegex.test(value.replace(/\s/g, ""));
  };

  const validateExpDate = (value: string) => {
    const expDateRegex = /^(0[1-9]|1[0-2])\/\d{2}$/; // MM/YY format
    if (!expDateRegex.test(value)) return false;
    const [month, year] = value.split("/").map(Number);
    const currentDate = new Date();
    const currentYear = Number(String(currentDate.getFullYear()).slice(2));
    const currentMonth = currentDate.getMonth() + 1;
    return (
      year > currentYear || (year === currentYear && month >= currentMonth)
    );
  };

  const validateCvv = (value: string) => {
    const cvvRegex = /^\d{3,4}$/;
    return cvvRegex.test(value);
  };

  const validateNonEmpty = (value: string) => {
    return value.trim() !== "";
  };

  const validateName = (value: string) => {
    const nameRegex = /^[a-zA-Z\s]{2,}$/; // Only letters and spaces, minimum 2 characters
    return nameRegex.test(value);
  };

  const validateAddress = (value: string) => {
    return value.length >= 5 && value.length <= 100; // Minimum 5 characters, maximum 100
  };

  const validateCityStateCountry = (value: string) => {
    return value.length >= 2 && value.length <= 50; // Minimum 2 characters, maximum 50
  };

  const validateZipCode = (value: string) => {
    const zipCodeRegex = /^\d{5,10}$/; // 5 to 10 digits
    return zipCodeRegex.test(value);
  };

  const handleExpDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    if (value.length >= 2) {
      value = value.slice(0, 2) + "/" + value.slice(2); // Insert '/'
    }
    setExpDate(value);
  };

  const handleSave = () => {
    const cleanedCardNumber = cardNumber.replace(/\s/g, "");
    if (!validateForm()) {
      return;
    }
    onSave(cleanedCardNumber, expDate, cvv, billingAddress);
    onClose();
  };

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [cardNumber, expDate, cvv, billingAddress]);

  const validateForm = () => {
    const newErrors = {
      cardNumber: validateCardNumber(cardNumber) ? "" : "Invalid card number.",
      expDate: validateExpDate(expDate) ? "" : "Invalid expiration date.",
      cvv: validateCvv(cvv) ? "" : "Invalid CVV.",
      firstName: validateNonEmpty(billingAddress.firstName)
        ? ""
        : "First name is required.",
      lastName: validateNonEmpty(billingAddress.lastName)
        ? ""
        : "Last name is required.",
      address: validateNonEmpty(billingAddress.address)
        ? ""
        : "Address is required.",
      city: validateNonEmpty(billingAddress.city) ? "" : "City is required.",
      state: validateNonEmpty(billingAddress.state) ? "" : "State is required.",
      zip: validateZipCode(billingAddress.zip) ? "" : "Invalid ZIP code.",
      country: validateNonEmpty(billingAddress.country)
        ? ""
        : "Country is required.",
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  if (!visible) return null;

  return (
    <div style={styles.modal}>
      <h2>Enter Card Details</h2>
      <input
        type="text"
        placeholder="First Name"
        value={billingAddress.firstName}
        onChange={(e) =>
          setBillingAddress({ ...billingAddress, firstName: e.target.value })
        }
        onBlur={() => setTouched({ ...touched, firstName: true })}
        minLength={2}
      />
      {touched.firstName && !validateName(billingAddress.firstName) && (
        <span style={styles.error}>
          First name must contain only letters and be at least 2 characters.
        </span>
      )}

      <input
        type="text"
        placeholder="Last Name"
        value={billingAddress.lastName}
        onChange={(e) =>
          setBillingAddress({ ...billingAddress, lastName: e.target.value })
        }
        onBlur={() => setTouched({ ...touched, lastName: true })}
        minLength={2}
      />
      {touched.lastName && !validateName(billingAddress.lastName) && (
        <span style={styles.error}>
          Last name must contain only letters and be at least 2 characters.
        </span>
      )}
      <input
        type="text"
        placeholder="Address"
        value={billingAddress.address}
        onChange={(e) =>
          setBillingAddress({ ...billingAddress, address: e.target.value })
        }
        onBlur={() => setTouched({ ...touched, address: true })}
      />
      {touched.address && !validateAddress(billingAddress.address) && (
        <span style={styles.error}>
          Address must be between 5 and 100 characters.
        </span>
      )}

      <input
        type="text"
        placeholder="City"
        value={billingAddress.city}
        onChange={(e) =>
          setBillingAddress({ ...billingAddress, city: e.target.value })
        }
        onBlur={() => setTouched({ ...touched, city: true })}
      />
      {touched.city && !validateCityStateCountry(billingAddress.city) && (
        <span style={styles.error}>
          City must be between 2 and 50 characters.
        </span>
      )}

      <input
        type="text"
        placeholder="State"
        value={billingAddress.state}
        onChange={(e) =>
          setBillingAddress({ ...billingAddress, state: e.target.value })
        }
        onBlur={() => setTouched({ ...touched, state: true })}
      />
      {touched.state && !validateCityStateCountry(billingAddress.state) && (
        <span style={styles.error}>
          State must be between 2 and 50 characters.
        </span>
      )}

      <input
        type="text"
        placeholder="ZIP Code"
        value={billingAddress.zip}
        onChange={(e) =>
          setBillingAddress({ ...billingAddress, zip: e.target.value })
        }
        onBlur={() => setTouched({ ...touched, zip: true })}
      />
      {touched.zip && !validateZipCode(billingAddress.zip) && (
        <span style={styles.error}>
          ZIP code must be between 5 and 10 digits.
        </span>
      )}

      <input
        type="text"
        placeholder="Country"
        value={billingAddress.country}
        onChange={(e) =>
          setBillingAddress({ ...billingAddress, country: e.target.value })
        }
        onBlur={() => setTouched({ ...touched, country: true })}
      />
      {touched.country && !validateCityStateCountry(billingAddress.country) && (
        <span style={styles.error}>
          Country must be between 2 and 50 characters.
        </span>
      )}
      <input
        type="text"
        placeholder="Card Number"
        value={cardNumber}
        onChange={(e) => setCardNumber(e.target.value)}
        onBlur={() => setTouched({ ...touched, cardNumber: true })}
      />
      {touched.cardNumber && errors.cardNumber && (
        <span style={styles.error}>{errors.cardNumber}</span>
      )}

      <input
        type="text"
        placeholder="Expiration Date (MM/YY)"
        value={expDate}
        onChange={handleExpDateChange} // Use the new formatting function
        onBlur={() => setTouched({ ...touched, expDate: true })}
      />
      {touched.expDate && errors.expDate && (
        <span style={styles.error}>{errors.expDate}</span>
      )}

      <input
        type="text"
        placeholder="CVV"
        value={cvv}
        onChange={(e) => setCvv(e.target.value)}
        onBlur={() => setTouched({ ...touched, cvv: true })}
      />
      {touched.cvv && errors.cvv && (
        <span style={styles.error}>{errors.cvv}</span>
      )}

      <button onClick={handleSave} disabled={!isFormValid}>
        Save Card
      </button>
      <button onClick={onClose}>Close</button>
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  modal: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  addCardButton: {
    backgroundColor: 'transparent',
    color: '#007bff',
    border: '2px solid #007bff',
    borderRadius: '20px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'all 0.3s',
    width: '160px',
  },

  error: {
    color: "red",
    fontSize: "12px",
    marginBottom: "10px",
    display: "block",
  },
  savedCard: {
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    width: "90%",
    margin: "10px auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  selectedCard: {
    borderColor: "#007bff",
    borderWidth: "2px",
    borderStyle: "solid",
  },
  cardBrand: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#333",
  },
  cardDetails: {
    fontSize: "15px",
    color: "#555",
  },
  buttonStyle: {
    display: "block",
    width: "160px",
    height: "40px",
    margin: "30px auto",
    backgroundColor: "#007bff",
    color: "#fff",
    borderRadius: "40px",
    textAlign: "center",
    lineHeight: "40px",
    cursor: "pointer",
  },
  alternativeButtonStyle: {
    backgroundColor: "#FFFFFF",
    border: `2px solid #007bff`,
    padding: "15px",
    borderRadius: "100px",
    textAlign: "center",
    cursor: "pointer",
    width: "160px",
    margin: "30px auto",
  },

  loader: {
    textAlign: "center",
    margin: "20px 0",
    fontSize: "18px",
    color: "#007bff",
  },
};

export default SavedCards;
